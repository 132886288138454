import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ColorScale, HorizontalBarChartGrouped } from "@eds/vanilla";
import Loading from "../../../components/Loading/Loading";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import EDSCustomMultiselect from "../../../components/EDSCustomMultiselect/EDSCustomMultiselect";
import { useGetSafetyActionsMutation } from "../../../redux/services/sitewalksAnalyticsApi";
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";

export default function SafetyActionsGraph({
  param,
  isAutoCall,
  getAnalyticsGraphsToggle,
  setAnalyticsGraphsToggle,
  validateFilterValues,
  validationMsg,
}) {
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();
  const types = [
    { formId: "auditor", form_name: "Auditor" },
    { formId: "EswUniqueId", form_name: "Id" },
    { formId: "location", form_name: "Location" },
    { formId: "owner", form_name: "Owner" },
  ];
  const [
    getSafetyActions,
    { isError, isLoading, data: { data, message } = {} },
  ] = useGetSafetyActionsMutation();

  const error =
    validationMsg ||
    (message !== "Success" && message) ||
    (isError && "Internal Server Error. Please try again");

  const handleIsOpen = () => {
    const isValid = validateFilterValues("safetyActions");
    isValid &&
      !getAnalyticsGraphsToggle.safetyActions &&
      getSafetyActions(JSON.stringify(param));

    setAnalyticsGraphsToggle((val) => ({
      ...val,
      safetyActions: !val.safetyActions,
    }));
  };

  useEffect(() => {
    const isValid = validateFilterValues("safetyActions");
    isValid &&
      isAutoCall &&
      getAnalyticsGraphsToggle.safetyActions &&
      getSafetyActions(JSON.stringify(param));
  }, [isAutoCall]);

  useEffect(() => {
    if (!data?.length) {
      return;
    }

    const groupByData = data?.reduce((result, obj) => {
      const keyValue = obj[selected];
      result[keyValue] = result[keyValue] || { open: 0, closed: 0 };
      result[keyValue].open += obj.open_count;
      result[keyValue].closed += obj.close_count;
      return result;
    }, {});
    const chartData = {
      common: [],
      series: [],
    };
    const openObj = { name: "Open", values: [] };
    const closedObj = { name: "Closed", values: [] };
    Object.entries(groupByData).forEach((val) => {
      const [key, { open, closed }] = val;
      chartData.common.push(key);
      openObj.values.push(open);
      closedObj.values.push(closed);
    });
    chartData.series = [openObj, closedObj];
    const chartHeight = chartData.common.length * 60;
    const mycolorScale = new ColorScale({ reverse: true });
    const chart = new HorizontalBarChartGrouped({
      element: document.getElementById("safetyActionsByReport-Graph"),
      data: chartData,
      x: { unit: "Count" },
      height: chartHeight,
      colorScale: mycolorScale,
      onSelect: (common) => {
        if (selected === "EswUniqueId") navigate(`/actions?id=${common}`);
      },
    });
    mycolorScale.init();
    chart.init();
    return () => {
      chart.destroy();
      let element = document.getElementById("safetyActionsByReport-Graph");
      while (element?.firstChild) {
        element.removeChild(element.firstChild);
      }
    };
  }, [data, selected]);

  return (
    <li
      tabIndex={3}
      className={`tile mb-sm accordion-item ${
        getAnalyticsGraphsToggle.safetyActions ? "opened" : ""
      }`}
    >
      <div className="title stickyTileHeader" onClick={handleIsOpen}>
        <div className="header p-sm">
          <div className="left">Safety Actions</div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="column sm-10"></div>
          <div className="column sm-2">
            <EDSCustomMultiselect
              setStatusOption={(val) => {
                setSelected(val[0] || "");
              }}
              formDetailsUG={types}
              defaultValues={["EswUniqueId"]}
              filterName="safetyActions-groupBy-Select"
              dataType="single"
            />
          </div>
          <div className="column sm-12">
            {isLoading && <Loading />}
            {error && (
              <AlertEDS errMsg={error} status_code={201} close={false} />
            )}
            {!error && data?.length ? (
              <>
                <CustomGraphActions
                  nodeId={"safetyActionsByReport-Graph"}
                  fileName={"Safety_Actions_Graph"}
                  data={data || []}
                />
                <div id="safetyActionsByReport-Graph"></div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </li>
  );
}
