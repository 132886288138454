import { ColorScale, HorizontalBarChartStacked } from "@eds/vanilla";
import { useEffect } from "react";
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";
export default function AuditCountBySupplier({ auditCountBySupplier }) {
  const getChartData = () => {
    const regions = auditCountBySupplier.reduce(
      (acc, val) => (acc.includes(val.Location) ? acc : [...acc, val.Location]),
      []
    );
    const supplierNames = auditCountBySupplier.reduce(
      (acc, val) =>
        acc.includes(val["Supplier Name"])
          ? acc
          : [...acc, val["Supplier Name"]],
      []
    );
    const data = {
      common: supplierNames,
      series: regions.map((region) => {
        const count = [];
        supplierNames.forEach((name) => {
          const obj = auditCountBySupplier.find(
            (val) => val.Location === region && val["Supplier Name"] === name
          );
          count.push(obj?.Count || 0);
        });
        return {
          name: region,
          values: count,
        };
      }),
    };
    return data;
  };

  useEffect(() => {
    const data = getChartData();
    const chartHeight = data?.common?.length * 40;
    const mycolorScale = new ColorScale({ reverse: true });
    const chart = new HorizontalBarChartStacked({
      element: document.getElementById("auditCountbySupplierGraph"),
      data: data,
      x: { unit: "Count" },
      height: chartHeight,
      colorScale: mycolorScale,
    });
    mycolorScale.init();
    chart.init();
    return () => {
      chart.destroy();
      let element = document.getElementById("auditCountbySupplierGraph");
      while (element?.firstChild) {
        element.removeChild(element.firstChild);
      }
    };
  }, [auditCountBySupplier]);

  return (
    <>
      <CustomGraphActions
        nodeId={"auditCountbySupplierGraph"}
        fileName={"Audit_CountBySupplier"}
        data={auditCountBySupplier}
      />
      <div id="auditCountbySupplierGraph"></div>
    </>
  );
}
