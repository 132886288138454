import Loading from "../Loading/Loading";
import "./Card.scss";
export default function Card({
  title,
  count,
  loadingVal,
  manualApprovalCount = -1,
  hoverText,
  isHoverTextRequire,
}) {
  return (
    <div className="data-box card">
      <div className="data-value">
        {loadingVal ? (
          <Loading width={"28"} height={"28"} />
        ) : (
          <>
            {count}
            {manualApprovalCount > -1 && (
              <span className="text-xl">
                &nbsp;({manualApprovalCount.toFixed(0)}%)
              </span>
            )}
          </>
        )}
      </div>
      {isHoverTextRequire ? (
        <div className="data-title">
          <span className="tooltip">
            {title}
            {title === "Domains" || title === "AI Assisted Approvals" ? (
              <span className="message right">{hoverText}</span>
            ) : (
              <span className="message top-end">{hoverText}</span>
            )}
          </span>
        </div>
      ) : (
        <div className="data-title">{title}</div>
      )}
    </div>
  );
}
