import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import _ from "lodash";
import { getReportsFiltersData } from "../../api/EHSReports.api";
import {
  getSiteWalkDataAPi,
  getSiteWalkData2APi,
  getSiteWalkResponsesData,
  getSiteWalkAuditCountBySupplierData,
  getCommentsNoComments
} from "../../api/SiteWalk.api";
import Loading from "../../components/Loading/Loading";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import SiteWalkGraph from "./Graphs/SiteWalkGraph";
import SiteWalkGraph2 from "./Graphs/SiteWalkGraph2";
import CustomDatePickerForm from "../../components/CustomDatePickerForm/CustomDatePickerForm";
import SafeResponsesUnsafeResponsesGraph from "./Graphs/SafeResponsesUnsafeResponsesGraph";
import UnsafeResponsePieChart from "./Graphs/UnsafeResponsePieChart";
import AuditCountBySupplier from "./Graphs/AuditCountBySupplier";
import { AuditsByAuditType } from "./Graphs/AuditsByAuditType";
import "./SiteWalk.css";
import SafetyActionsGraph from "./Graphs/SafetyActionsGraph";
import { AuditsByCustomer } from "./Graphs/AuditsByCustomer";
import { AuditsByBusiness } from "./Graphs/AuditsByBusiness";
import { CommentsNoCommentsGraph } from "./Graphs/CommentsNoCommentsGraph";

export default function SiteWalk() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loadingVal, setLoading] = useState({
    siteWalkbyRegion: false,
    siteWalkbyName: false,
    siteWalkbyActionItems: false,
    actionsByOwner: false,
    actionsByAuditor: false,
    responsesByRegion: false,
    auditCount: false,
    auditCountBySupplier: false,
    auditCountByAuditType: false,
    commentsNoComments: false
  });
  const [formIdData, setFormIdData] = useState([]);
  const [errMsg, setErrMsg] = useState({
    siteWalkbyRegion: "",
    siteWalkbyName: "",
    siteWalkbyActionItems: "",
    actionsByOwner: "",
    actionsByAuditor: "",
    responsesByRegion: "",
    auditCount: "",
    auditCountBySupplier: "",
    auditCountByAuditType: "",
    commentsNoComments: ""
  });
  const [getSiteWalkData, setSiteWalkData] = useState({});
  const [getSiteWalkData2, setSiteWalkData2] = useState({});
  const [getResponsesData, setResponsesData] = useState({});
  const [getAuditCountBySupplierData, setAuditCountBySupplierData] = useState(
    {}
  );
  // const [getAuditCountByAuditTypeData, setAuditCountByAuditTypeData] = useState(
  //   {}
  // );
  const [regionColumnName, setRegionColumnName] = useState("Region");
  const [currentDay, setCurrentDay] = useState(
    moment().local().format("YYYY-MM-DD")
  );
  const [isAutoCall, setIsAutoCall] = useState(false);
  // const { getUserAccess } = useContext(UserContext);
  // const [sitewalkAccess, setSitewalkAccess] = useState([]);
  // const [filtersAccess, setFiltersAccess] = useState([]);
  const [filterData, setfiltersData] = useState({});
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [filtersErrMsg, setFiltersErrMsg] = useState("");
  const [getUsersOption, setUsersOption] = useState([]);
  const [getMarketDetails, setMarketDetails] = useState([]);
  const [getCustomerDetails, setCustomerDetails] = useState([]);
  const [getRegionDetails, setRegionDetails] = useState([]);
  const [getSupplierDetails, setSupplierDetails] = useState([]);
  const [getStateDetails, setStateDetails] = useState([]);
  const [validationMsg, setValidationMsg] = useState("");
  const [getAnalyticsGraphsToggle, setAnalyticsGraphsToggle] = useState({
    siteWalkbyRegion: false,
    siteWalkbyName: false,
    siteWalkbyActionItems: false,
    actionsByOwner: false,
    actionsByAuditor: false,
    responsesByRegion: false,
    auditCount: false,
    auditCountBySupplier: false,
    auditCountByAuditType: false,
    commentsNoComments: false,
  });
  const [selectedBusinessUnit, setSelecetedBusinessUnit] = useState([]);
  const { employeeId } = useSelector((state) => state.loggedInUser.user);
  const { headers, headersList } = useSelector((state) => state.envVariables);
  const features = useSelector((state) => state.userAccess.features);
  const usergroupId = useSelector(
    (state) => state.loggedInUser.user.usergroupId
  );
  const [getCommentsNoCommentsData, setCommentsNoCommentsData] = useState([]);

  useEffect(() => {
    let locationName = features?.find((val) => val.name === "LOCATION").value;
    if (_.isEmpty(locationName)) {
      locationName = "Region";
    }
    setRegionColumnName(locationName);
  }, []);

  let datepickerData = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    formIdData,
    setFormIdData,
    getUsersOption,
    setUsersOption,
    getMarketDetails,
    setMarketDetails,
    getCustomerDetails,
    setCustomerDetails,
    getRegionDetails,
    setRegionDetails,
    getSupplierDetails,
    setSupplierDetails,
    getStateDetails,
    setStateDetails,
    selectedBusinessUnit,
    setSelecetedBusinessUnit,
  };

  const validateFilterValues = (property) => {
    let errorMsg = "";
    if (_.isNull(startDate)) {
      errorMsg = "Please select Start Date";
    } else if (_.isNull(endDate)) {
      errorMsg = "Please select End Date";
    } else if (_.isEmpty(getRegionDetails)) {
      errorMsg = "Please select at least one " + regionColumnName;
    } else if (_.isEmpty(getStateDetails)) {
      errorMsg = "Please select at least one State";
    } else if (_.isEmpty(getUsersOption)) {
      errorMsg = "Please select at least one Auditor";
    } else if (
      _.isEmpty(getMarketDetails) &&
      features?.some((val) => val.name === "MARKET" && val.value)
    ) {
      errorMsg = "Please select at least one Market";
    } else if (
      _.isEmpty(getCustomerDetails) &&
      features?.some((val) => val.name === "CUSTOMER" && val.value)
    ) {
      errorMsg = "Please select at least one Customer";
    } else if (_.isEmpty(getSupplierDetails)) {
      errorMsg = "Please select at least one Supplier";
    } else if (_.isEmpty(formIdData)) {
      errorMsg = "Please select at least one Form";
    } else if (_.isEmpty(selectedBusinessUnit)) {
      errorMsg = "Please select at least one Bussiness Unit";
    } else {
      errorMsg = "";
    }
    setErrMsg((val) =>
      Object.keys(val).reduce((acc, key) => {
        if (key === property) {
          acc[key] = errorMsg;
        } else {
          acc[key] = val[key];
        }
        return acc;
      }, {})
    );
    setValidationMsg(errorMsg);
    return errorMsg.length < 1;
  };

  let param = {
    start_date: moment(startDate).format("YYYY-MM-DD"),
    end_date: moment(endDate).format("YYYY-MM-DD"),
    formId: formIdData,
    response_type: "2",
    status: "*",
    region: getRegionDetails,
    markets: getMarketDetails,
    customers: getCustomerDetails,
    suppliers: getSupplierDetails,
    reporter: getUsersOption,
    state: getStateDetails,
    timeZone: moment.tz.guess(true),
    business_unit: selectedBusinessUnit,
  };

  const getSiteWalkDataByAnalyticsToggle = (graphName, isAccordion = true) => {
    const isValidParam = validateFilterValues(graphName);
    if (graphName === "siteWalkbyRegion") {
      isValidParam &&
        (!getAnalyticsGraphsToggle.siteWalkbyRegion || !isAccordion) &&
        getSiteWalkDataAPi(
          param,
          setLoading,
          setErrMsg,
          setSiteWalkData,
          headersList
        );
      isAccordion &&
        setAnalyticsGraphsToggle((val) => ({
          ...val,
          siteWalkbyRegion: !val.siteWalkbyRegion,
        }));
    } else if (graphName === "siteWalkbyName") {
      isValidParam &&
        (!getAnalyticsGraphsToggle.siteWalkbyName || !isAccordion) &&
        getSiteWalkData2APi(
          param,
          setLoading,
          setErrMsg,
          setSiteWalkData2,
          headersList
        );
      isAccordion &&
        setAnalyticsGraphsToggle((val) => ({
          ...val,
          siteWalkbyName: !val.siteWalkbyName,
        }));
    } else if (graphName === "responsesByRegion") {
      isValidParam &&
        (!getAnalyticsGraphsToggle.responsesByRegion || !isAccordion) &&
        getSiteWalkResponsesData(
          param,
          setLoading,
          setErrMsg,
          setResponsesData,
          headersList
        );
      isAccordion &&
        setAnalyticsGraphsToggle((val) => ({
          ...val,
          responsesByRegion: !val.responsesByRegion,
        }));
    }
    else if (graphName === "auditCountBySupplier") {
      isValidParam &&
        (!getAnalyticsGraphsToggle.auditCountBySupplier || !isAccordion) &&
        getSiteWalkAuditCountBySupplierData(
          param,
          setLoading,
          setErrMsg,
          setAuditCountBySupplierData,
          headersList
        );
      isAccordion &&
        setAnalyticsGraphsToggle((val) => ({
          ...val,
          auditCountBySupplier: !val.auditCountBySupplier,
        }));
    }
    else if (graphName === "commentsNoComments") {
      isValidParam &&
        (!getAnalyticsGraphsToggle.commentsNoComments || !isAccordion) &&
        getCommentsNoComments(
          param,
          setLoading,
          setErrMsg,
          setCommentsNoCommentsData,
          headersList
        );
      isAccordion &&
        setAnalyticsGraphsToggle((val) => ({
          ...val,
          commentsNoComments: !val.commentsNoComments,
        }));
    }
    else {
    }
  };

  const getDisableApplyToggle = useMemo(() => {
    return Object.values(loadingVal).includes(true) || filtersLoading;
  }, [loadingVal, filtersLoading]);

  useEffect(() => {
    if (startDate && endDate) {
      const param = {
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        timeZone: moment.tz.guess(true),
      };
      getReportsFiltersData(
        param,
        setfiltersData,
        setFiltersLoading,
        setFiltersErrMsg,
        headers
      );
    }
  }, [startDate, endDate, headers]);

  useEffect(() => {
    setCustomerDetails(filterData?.customers || []);
    setMarketDetails(filterData?.markets || []);
    setSupplierDetails(filterData?.suppliers || []);
    const defaultReporter = Object.entries(
      filterData?.reporters?.find(
        (val) => Object.entries(val)?.[0]?.[0] === employeeId
      ) || []
    )?.[0]?.[0];
    setUsersOption(_.isEmpty(defaultReporter) ? [] : [defaultReporter]);
    setRegionDetails(filterData?.locations || []);
    setSelecetedBusinessUnit(filterData?.business_unit || []);
    setStateDetails(filterData?.state || []);
    const defaultForm = filterData?.form?.[0]?.formId;
    setFormIdData(
      defaultForm || typeof defaultForm !== "undefined" ? [defaultForm] : []
    );
    if (!_.isEmpty(filterData)) {
      setIsAutoCall(true);
    }

    const updateStickyHeaderTopValue = () => {
      const _headerList = document.querySelectorAll(".stickyTileHeader");
      const _filtersHeight =
        document.querySelector(".filterDiv")?.offsetHeight || 0;
      if (_headerList?.length) {
        _headerList.forEach((header) => {
          header.style.top = _filtersHeight + "px";
        });
      }
    };

    window.addEventListener("resize", updateStickyHeaderTopValue);
    window.dispatchEvent(new Event("resize"));

    return () => {
      window.removeEventListener("resize", updateStickyHeaderTopValue);
    };

  }, [filterData, employeeId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newDay = moment().local().format("YYYY-MM-DD");
      if (currentDay !== newDay) {
        setCurrentDay(newDay);
        setStartDate(
          new Date(
            moment(currentDay, "YYYY-MM-DD").format("YYYY-MM-DD 00:00:00")
          )
        );
        setEndDate(
          new Date(moment(newDay, "YYYY-MM-DD").format("YYYY-MM-DD 23:59:59"))
        );
        setIsAutoCall(true);
      }
    }, 60000);
    return () => clearInterval(intervalId);

  }, [currentDay]);

  useEffect(() => {
    if (isAutoCall) {
      const defaultToggle =
        Object.values(getAnalyticsGraphsToggle).indexOf(true) < 0;
      if (defaultToggle) {
        getSiteWalkDataByAnalyticsToggle("siteWalkbyRegion");
      } else {
        for (let i in getAnalyticsGraphsToggle) {
          if (getAnalyticsGraphsToggle[i]) {
            getSiteWalkDataByAnalyticsToggle(i, false);
          }
        }
      }
      setIsAutoCall(false);
    }
  }, [isAutoCall]);

  return (
    <div className="row">
      <div className="tile filterDiv">
        <CustomDatePickerForm
          formName="site-walk"
          datepickerData={datepickerData}
          filterData={filterData}
          // submitData={() => {
          //   setLoading({ siteWalkbyRegion: true, siteWalkbyName: true });
          // }}
          callApply={() => {
            setIsAutoCall(true);
          }}
          disableButtton={getDisableApplyToggle}
        // filtersAccess={filtersAccess}
        />
      </div>
      {filtersLoading ? (
        <Loading />
      ) : (
        <>
          {filtersErrMsg !== "Success" ? (
            <div className="tile sm-12 md-12 lg-12 xl-12">
              <AlertEDS
                errMsg={filtersErrMsg}
                status_code={201}
                close={false}
              />
            </div>
          ) : (
            <div className="accordion column sm-12 md-12 lg-12 xl-12">
              <ul className="accordion-list">
                <li
                  tabIndex={0}
                  className={`tile mb-sm accordion-item ${getAnalyticsGraphsToggle.siteWalkbyRegion ? "opened" : ""
                    }`}
                >
                  <div
                    className="title stickyTileHeader"
                    onClick={() => {
                      getSiteWalkDataByAnalyticsToggle("siteWalkbyRegion");
                    }}
                  >
                    <div className="header p-sm">
                      <div className="left">
                        Total Site Walk Completion Count – By Location
                      </div>
                    </div>
                  </div>
                  <div className="content">
                    {loadingVal.siteWalkbyRegion ? (
                      <Loading />
                    ) : (
                      <>
                        {errMsg.siteWalkbyRegion !== "Success" ? (
                          <AlertEDS
                            errMsg={errMsg.siteWalkbyRegion}
                            status_code={201}
                            close={false}
                          />
                        ) : (
                          <SiteWalkGraph
                            site_walk_region={
                              !_.isEmpty(getSiteWalkData) && getSiteWalkData
                            }
                            regionColumnName={regionColumnName}
                          />
                        )}
                      </>
                    )}
                  </div>
                </li>



                <li
                  tabIndex={1}
                  className={`tile mb-sm accordion-item ${getAnalyticsGraphsToggle.siteWalkbyName ? "opened" : ""
                    }`}
                >
                  <div
                    className="title stickyTileHeader"
                    onClick={() => {
                      getSiteWalkDataByAnalyticsToggle("siteWalkbyName");
                    }}
                  >
                    <div className="header p-sm">
                      <div className="left">
                        Total Site Walk Completion Count – By Auditor
                      </div>
                    </div>
                  </div>
                  <div className="content">
                    {loadingVal.siteWalkbyName ? (
                      <Loading />
                    ) : (
                      <>
                        {errMsg.siteWalkbyName !== "Success" ? (
                          <AlertEDS
                            errMsg={errMsg.siteWalkbyName}
                            status_code={201}
                            close={false}
                          />
                        ) : (
                          <SiteWalkGraph2
                            site_walk_name={
                              !_.isEmpty(getSiteWalkData2) && getSiteWalkData2
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </li>
                <SafetyActionsGraph
                  param={param}
                  isAutoCall={isAutoCall}
                  getAnalyticsGraphsToggle={getAnalyticsGraphsToggle}
                  setAnalyticsGraphsToggle={setAnalyticsGraphsToggle}
                  validateFilterValues={validateFilterValues}
                  validationMsg={validationMsg}
                />
                <li
                  tabIndex={5}
                  className={`tile mb-sm accordion-item ${getAnalyticsGraphsToggle.responsesByRegion ? "opened" : ""
                    }`}
                >
                  <div
                    className="title stickyTileHeader"
                    onClick={() => {
                      getSiteWalkDataByAnalyticsToggle("responsesByRegion");
                    }}
                  >
                    <div className="header p-sm">
                      <div className="left">
                        Safe Responses Vs Unsafe Responses
                      </div>
                    </div>
                  </div>
                  <div className="content">
                    {loadingVal.responsesByRegion ? (
                      <Loading />
                    ) : (
                      <>
                        {errMsg.responsesByRegion !== "Successful" ? (
                          <AlertEDS
                            errMsg={errMsg.responsesByRegion}
                            status_code={201}
                            close={false}
                          />
                        ) : (
                          <> <SafeResponsesUnsafeResponsesGraph
                            safeUnsafeResponsesData={getResponsesData}
                          />
                            {features.some(
                              (val) => val.name === "SAFE_UNSAFE_RESPONSE_GRAPH" && val.value
                            ) && (
                                <>
                                  <div className="header p-sm">
                                    <div className="left">Unsafe Responses</div>
                                  </div>
                                  <UnsafeResponsePieChart
                                    UnsafeResponseData={getResponsesData}
                                  />
                                </>
                              )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </li>
                {/* <AuditCountByWorkerTypeGraph
                    param={param}
                    isAutoCall={isAutoCall}
                    getAnalyticsGraphsToggle={getAnalyticsGraphsToggle}
                    setAnalyticsGraphsToggle={setAnalyticsGraphsToggle}
                    validationMsg={validationMsg}
                    validateFilterValues={validateFilterValues}
                  /> */}
                {!["27", "29"].includes(usergroupId) && (
                  <li
                    tabIndex={7}
                    className={`tile mb-sm accordion-item ${getAnalyticsGraphsToggle.auditCountBySupplier
                      ? "opened"
                      : ""
                      }`}
                  >
                    <div
                      className="title stickyTileHeader"
                      onClick={() => {
                        getSiteWalkDataByAnalyticsToggle(
                          "auditCountBySupplier"
                        );
                      }}
                    >
                      <div className="header p-sm">
                        <div className="left">Audit Count by Supplier</div>
                      </div>
                    </div>
                    <div className="content">
                      {loadingVal?.auditCountBySupplier ? (
                        <Loading />
                      ) : (
                        <>
                          {errMsg?.auditCountBySupplier !== "Success" ? (
                            <AlertEDS
                              errMsg={errMsg?.auditCountBySupplier}
                              status_code={201}
                              close={false}
                            />
                          ) : (
                            <AuditCountBySupplier
                              auditCountBySupplier={
                                getAuditCountBySupplierData
                              }
                            />
                          )}
                        </>
                      )}
                    </div>
                  </li>
                )}

                <AuditsByAuditType
                  param={param}
                  isAutoCall={isAutoCall}
                  getAnalyticsGraphsToggle={getAnalyticsGraphsToggle}
                  setAnalyticsGraphsToggle={setAnalyticsGraphsToggle}
                  validateFilterValues={validateFilterValues}
                  validationMsg={validationMsg}
                />

                <AuditsByBusiness
                  param={param}
                  isAutoCall={isAutoCall}
                  getAnalyticsGraphsToggle={getAnalyticsGraphsToggle}
                  setAnalyticsGraphsToggle={setAnalyticsGraphsToggle}
                  validateFilterValues={validateFilterValues}
                  validationMsg={validationMsg}
                />

                {features.some(
                  (val) => val.name === "CUSTOMER" && val.value
                ) && (
                    <AuditsByCustomer
                      param={param}
                      isAutoCall={isAutoCall}
                      getAnalyticsGraphsToggle={getAnalyticsGraphsToggle}
                      setAnalyticsGraphsToggle={setAnalyticsGraphsToggle}
                      validateFilterValues={validateFilterValues}
                      validationMsg={validationMsg}
                    />
                  )}



                <li
                  tabIndex={11}
                  className={`tile mb-sm accordion-item ${getAnalyticsGraphsToggle.commentsNoComments ? "opened" : ""
                    }`}
                >
                  <div
                    className="title stickyTileHeader"
                    onClick={() => {
                      getSiteWalkDataByAnalyticsToggle("commentsNoComments");
                    }}
                  >
                    <div className="header p-sm">
                      <div className="left">
                        Comments Statistics
                      </div>
                    </div>
                  </div>
                  <div className="content">
                    {loadingVal.commentsNoComments ? (
                      <Loading />
                    ) : (
                      <>
                        {errMsg.commentsNoComments !== "Successful" ? (
                          <AlertEDS
                            errMsg={errMsg.commentsNoComments}
                            status_code={201}
                            close={false}
                          />
                        ) : (
                          <CommentsNoCommentsGraph
                            CommentsNoCommentsData={
                              !_.isEmpty(getCommentsNoCommentsData) && getCommentsNoCommentsData
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </li>


              </ul>
            </div>
          )}
        </>
      )}
    </div>
  );
}
