import { Table } from "@eds/vanilla";
import React, { useEffect, useState } from "react";
import { exportToCSVMethod } from "../../utils/helperMethod";
import _ from "lodash";
import { getDeleteUser } from "../../api/userManagement.api";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import Loading from "../../components/Loading/Loading";
import AlertWarnEDS from "../../components/AlertWarn/AlertWarnEDS";
import { updateUserStatus } from "../../api/userManagement.api";
import { resetUserPassword } from "../../api/userManagement.api";
import { useSelector } from "react-redux";
import RolesInfo from "./RolesInfo";
import { SetStateAction } from "babylonjs";

export default function UserManagementTable({ getUserData, handleUserRoles }) {
  const [getTableData, setTableData] = useState([]);
  const [getData, setData] = useState();
  const [loadingVal, setLoading] = useState(false);
  const [getUserDelete, setUserDelete] = useState(false);
  const [getResetPassword, setResetPassword] = useState(false);
  const [getActionInfo, setActionInfo] = useState({});
  const [getStatusAction, setStatusAction] = useState(false);
  const [getDeleteName, setDeleteName] = useState("");
  // const [getDeleteIdx, setDeleteIdx] = useState({});
  const [errMsg, setErrMsg] = useState({
    errorMsg: "",
    statusCode: "",
  });
  const [showRolesInfo, setShowRolesInfo] = useState(false);
  const { headersList } = useSelector((state) => state.envVariables);
  const rolesMap = useSelector((state) => state.userAccess.roles);

  useEffect(() => {
    setTableData([...getUserData]);
  }, [getUserData]);

  const CreateActionBtn = (isActive) => {
    const btnGroup = `
      <div class="btn-group horizontal" id="btnGroupH">
        ${isActive === "Y"
        ? `<button class="btn primary icon-btn changeStatus" title="Disable">
          <i class="icon icon-cross"></i>
        </button>
        <button class="btn primary icon-btn resetPassword" title="Reset Password">
          <i class="icon icon-reset-password"></i>
        </button>`
        : `<button class="btn primary icon-btn changeStatus" title="Enable">
          <i class="icon icon-check"></i>
        </button>
        <button class="btn primary icon-btn resetPassword" title="Reset Password" disabled>
          <i class="icon icon-reset-password"></i>
        </button>`
      }
        <button class="btn primary icon-btn editRoles" title="Edit Roles">
          <i class="icon icon-edit"></i>
        </button>           
        <button class="btn primary icon-btn deleteUser" title="Delete User">
          <i class="icon icon-trashcan"></i>
        </button>
      </div>
    `;
    return btnGroup;
  };

  const handleTableData = () => {
    let products = [];
    for (let tblData = getTableData.length - 1; tblData >= 0; tblData--) {
      products.push({
        UserName: `${getTableData[tblData]["firstName"].replace(/\s+/g, ' ').trim()} ${getTableData[tblData]["lastName"].replace(/\s+/g, ' ').trim()}`,
        Email_ID: getTableData[tblData]["email"],
        "Acc Created Date": getTableData[tblData]["createddate"],
        Company: getTableData[tblData]["company"],
        "Dashboard Access":
          getTableData[tblData]["dashboarduser"] === "Y" ? "Yes" : "No",
        Status:
          getTableData[tblData]["active_user"] === "Y" ? "Active" : "In-Active",
        "Paid User": getTableData[tblData]["paidUser"] === "Y" ? "Yes" : "No",
        EmpID: getTableData[tblData]["employeeId"],
        Action: getTableData[tblData] || {},
        Roles: getTableData[tblData]?.["roles"] || [],
        country: getTableData[tblData]["country"] || "",
      });
    }
    return products;
  };

  const updateUserStatusLocally = (empId, newStatus) => {
    setTableData((prevUserData) =>
      prevUserData.map((user) =>
        user.employeeId === empId ? { ...user, active_user: newStatus } : user
      )
    );
  };

  const handleStatusConfirm = async () => {
    const status = getActionInfo.status === "Disable" ? "N" : "Y";
    const respData = await updateUserStatus(
      {
        employeeId: getActionInfo.empId,
        active_user: status,
      },
      setErrMsg,
      headersList
    );
    if (respData.status_code === 200) {
      updateUserStatusLocally(getActionInfo.empId, status);
      setErrMsg({
        errorMsg: respData.message,
        statusCode: respData.status_code,
      });
      setTimeout(() => {
        setErrMsg({ errorMsg: "", statusCode: "" });
      }, 3000);
    } else {
      setErrMsg({
        errorMsg: respData.message,
        statusCode: respData.status_code,
      });
    }
    setStatusAction(false);
    setActionInfo({});
  };



  const handleresetPassword = (data) => {
    setResetPassword(true);
    setActionInfo({ empId: data.employeeId, email: data.email, userName: data.firstName + ' ' + data.lastName, });
  }

  const handleStatusAction = (data) => {
    const status = data.active_user === "Y" ? "Disable" : "Enable"
    setStatusAction(true);
    setActionInfo({ empId: data.employeeId, email: data.email, userName: data.firstName + ' ' + data.lastName, status: status });
  }

  const handleRolesInfo = () => {
    setShowRolesInfo(true);
  }

  useEffect(() => {
    const tableDOM = document.querySelector("#displayUser");
    if (_.isEmpty(getData)) {
      const table = new Table(tableDOM, {
        data: handleTableData(),
        columns: [
          {
            key: "UserName",
            title: "User Name",
            sort: "none",
          },
          {
            key: "Email_ID",
            title: "Email ID",
            sort: "none",
            cellClass: "text-wrap",
          },
          {
            key: "Acc Created Date",
            title: "Acc Created Date",
            sort: "desc",
          },
          {
            key: "Company",
            title: "Company",
            sort: "none",
          },
          {
            key: "Status",
            title: "Status",
            sort: "none",
          },
          {
            key: "Action",
            title: "Action",
            headerClass: "text-center",
            width: "170px",
            onCreatedCell: (td, data) => {
              td.innerHTML = CreateActionBtn(data.active_user);
              td.querySelector("button.changeStatus").addEventListener(
                "click",
                () => {
                  handleStatusAction({ ...data });
                  // statusAction(data.employeeId, data.active_user);
                }
              );
              td.querySelector("button.resetPassword").addEventListener(
                "click",
                () => {
                  handleresetPassword({ ...data });
                }
              );
              td.querySelector("button.editRoles").addEventListener(
                "click",
                () => {
                  handleUserRoles({}, "Save", { ...data });
                }
              );
              td.querySelector("button.deleteUser").addEventListener(
                "click",
                () => {
                  handleDelete({ ...data });
                }
              );
            },
          },
        ],
        sortable: true,
        expandable: true,
        onCreatedDetailsRow: (td, data) => {
          td.innerHTML = `<div>Country: ${data["country"]}</div>
          <div>Dashboard Access: ${data["Dashboard Access"]}</div>
          <div>Roles: ${data.Roles.reduce(
            (acc, val) => (acc ? `${acc}, ${val}` : val),
            ""
          )} <i class="icon icon-info-solid roleInfo" style="cursor:pointer"></i></div>
          `;
          td.querySelector("i.roleInfo").addEventListener(
            "click",
            () => {
              handleRolesInfo();
            }
          );
        },
      });
      table.init();
      setData(table);
    } else {
      getData.update(handleTableData());
    }
  }, [getTableData]);

  const handleDelete = (data) => {
    const _deleteNameMail = `${data.firstName} ${data.lastName} , ${data.email}`;
    setDeleteName(_deleteNameMail);
    setActionInfo({ empId: data.employeeId, email: data.email, userName: data.firstName + ' ' + data.lastName, });
    setUserDelete(true);
  };

  const handleDeleteConfirm = async () => {
    debugger
    const response = await getDeleteUser(setLoading, getActionInfo, headersList);
    setErrMsg({ ...response });
    colseTimeAlert();
    if (response.statusCode === 200) {
      const _deleteTableData = getTableData.filter(
        (val) => val.employeeId !== getActionInfo.empId
      );
      setTableData(_deleteTableData);
    }
    setActionInfo({});
    setUserDelete(false);
  };

  const handleResetPasswordConfirm = async () => {
    const respData = await resetUserPassword(
      {
        employeeId: getActionInfo.empId,
        emailId: getActionInfo.email,
      },
      setLoading,
      setErrMsg,
      headersList
    );
    colseTimeAlert();
    if (respData.status_code === 200) {
      setErrMsg({
        errorMsg: respData.message,
        statusCode: respData.status_code,
      });
    }
    setResetPassword(false);
    setActionInfo({});
  }

  const colseTimeAlert = () => {
    setTimeout(() => {
      setErrMsg({
        errorMsg: "",
        statusCode: "",
      });
    }, 3000);
  };

  const closeAlert = () => {
    setErrMsg({
      errorMsg: "",
      statusCode: "",
    });
  };

  const callExport = () => {
    const csvData = [];
    getTableData?.forEach((val) => {
      csvData.push({
        "User Name": val.firstName + " " + val.lastName,
        "Email ID": val.email,
        "Acc Created Date": val.createddate,
        Company: val.company,
        "Dashboard Access": val.dashboarduser === "Y" ? "Yes" : "No",
        Status: val.active_user === "Y" ? "Active" : "In-Active",
      });
    });
    exportToCSVMethod(csvData, "User List");
  };

  useEffect(() => {
    let _tr = document.querySelector(".stickyHeader thead tr");
    let _top = document.querySelector(".filterDiv").offsetHeight;
    if (_tr) {
      _tr.style.top = _top + "px";
    }
  }, [getUserData]);

  return (
    <div>
      {(getUserDelete || getResetPassword || getStatusAction) && (
        <AlertWarnEDS
          errTitle={"Please Confirm!"}
          errMsg={
            <>
              Are you sure you want to
              {getUserDelete && " delete user"}
              {getResetPassword && ' reset password for'}
              {getStatusAction &&` ${getActionInfo.status}`}
              <br />
              {getActionInfo.userName + ' , ' + getActionInfo.email} ?
            </>
          }
          btnText={getUserDelete ? "Delete" : getStatusAction ? getActionInfo.status : "Reset"}
          handleDeleteConfirm={getUserDelete ? handleDeleteConfirm : getStatusAction ? handleStatusConfirm : handleResetPasswordConfirm}
          setDelete={getUserDelete ? setUserDelete : getStatusAction ? setStatusAction : setResetPassword}
        />
      )}
      {showRolesInfo && (
        <RolesInfo setShowRolesInfo={setShowRolesInfo} />
      )
      }
      {loadingVal && (
        <div className="SiderBarLoader">
          <Loading />
        </div>
      )}
      {errMsg.errorMsg !== "" && (
        <AlertEDS
          errMsg={errMsg.errorMsg}
          status_code={errMsg.statusCode}
          close={true}
          closeAlert={closeAlert}
        />
      )}
      <div className="table-top">
        <div className="table-top-left">
          <div className="table-info"></div>
        </div>
        <div className="table-top-right">
          <div className="table-buttons">
            <button
              className="btn"
              id="exportData"
              onClick={() => callExport()}
            >
              Export
            </button>
          </div>
          <div className="table-search">
            <input
              type="text"
              className="with-icon"
              placeholder="Search for..."
            />
            <div className="suffix icon-inside">
              <i className="icon icon-search"></i>
            </div>
          </div>
        </div>
      </div>
      <table
        id="displayUser"
        className="table dashed compact stickyHeader horizonatalScroll"
      ></table>
      <div className="table-bottom">
        <div className="pagination-group">
          <ul className="pagination"></ul>
          <div className="actions">
            <label className="left">Go to</label>
            <input type="number" min="1" />

            <label className="left">Show</label>
            <div className="select" data-type="single">
              <button className="btn current-options">5</button>
              <div className="options-list">
                <div className="item active">5</div>
                <div className="item">10</div>
                <div className="item">15</div>
                <div className="item">All</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
