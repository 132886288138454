import { useEffect, useMemo, useState } from "react";
import MultiselectDropDown from "../../components/CustomMultiSelectDropDown/MultiSelectDropDown";
import EDSCustomMultiselect from "../../components/EDSCustomMultiselect/EDSCustomMultiselect";
import CountryCodes from "../LoneWorker/countryCode.json";
import { emailValidation } from "../../utils/helperMethod";
import {
  getCompanyAndRoles,
  UpdateUserDetails,
} from "../../api/userManagement.api";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import RolesInfo from "./RolesInfo";

export default function AddNewUser({
  getUserRoles,
  setUserRoles,
  callGetUsers,
}) {
  const [getFirstName, setFirstName] = useState("");
  const [getLastName, setLastName] = useState("");
  const [getEmail, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [getPhoneNo, setPhoneNo] = useState("");
  const [getAlternatePhoneNo, setAlternatePhoneNo] = useState("");
  const [getCountry, setCountry] = useState([]);
  const [getCompany, setCompany] = useState([]);
  const [getRoles, setRoles] = useState([]);
  const [getFilterList, setFilterList] = useState({
    roles: [],
    company: [],
  });
  const [getLoading, setLoading] = useState({
    getCall: false,
    updateCall: false,
  });
  const [getStatusMessage, setStatusMessage] = useState("");
  const [showRolesInfo, setShowRolesInfo] = useState(false);
  const { headers, headersList } = useSelector((state) => state.envVariables);

  const contriesDetails = useMemo(
    () =>
      CountryCodes?.map((val) => ({
        formId: `${val.CODE2}-${val.NAME}`,
        form_name: val.NAME,
      })) || [CountryCodes],
    []
  );

  useEffect(() => {
    getCompanyAndRoles(setLoading, setStatusMessage, setFilterList, headers);
  }, []);

  useEffect(() => {
    const { user, type, isOpen } = getUserRoles;
    setFirstName(user.firstName || "");
    setLastName(user.lastName || "");
    setEmail(user.email || "");
    setPhoneNo(user.phoneNumber || "");
    setAlternatePhoneNo(user.emergencyContact || "")
    setIsValidEmail(type === "Save");
    setRoles(user.roles || []);
    if (!isOpen) {
      setCountry([]);
      setCompany([]);
    }
  }, [getUserRoles]);

  const onCancel = () => {
    setUserRoles({ isOpen: false, user: {} });
    setStatusMessage("");
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
    if (event.target.value === "") {
      event.target.classList.add("invalid");
      event.target.classList.remove("valid");
    } else if (emailValidation(event)) {
      setIsValidEmail(false);
      event.target.classList.add("invalid");
      event.target.classList.remove("valid");
    } else {
      setIsValidEmail(true);
      event.target.classList.remove("invalid");
    }
  };

  const handleAddUserSubmit = async () => {
    const country = getCountry[0]?.split("-") || ["", ""];
    const user = {
      firstname: getFirstName,
      lastname: getLastName,
      email: getEmail,
      phoneNumber: getPhoneNo,
      emergencyContact: getAlternatePhoneNo,
      roles: getRoles,
      company: getCompany[0],
      countryCode: country[0],
      country: country[1],
    };
    const options = {
      method: getUserRoles.type === "Save" ? "PUT" : "POST",
      body: JSON.stringify(user),
      headers: headersList,
    };
    setLoading((val) => ({ ...val, updateCall: true }));
    await UpdateUserDetails(options).then((data) => {
      if (data.status_code === 200) {
        callGetUsers();
        onCancel();
      }
      setStatusMessage(data.message);
      setLoading((val) => ({ ...val, updateCall: false }));
    });
  };

  const handleRolesInfo = () => {
    setShowRolesInfo(true);
  }

  return (
    <>
      {showRolesInfo && (
        <RolesInfo setShowRolesInfo={setShowRolesInfo} />
      )
      }
      {getUserRoles.isOpen && (
        <div id="addnewUser-dialog" className="dialog show md" data-type="simple">
          <div className="content">
            <div className="top">
              <div className="title">
                {getUserRoles.type === "Save" ? "Edit User" : "Add New User"}
              </div>
              <div className="right">
                <button className="btn-icon" onClick={onCancel}>
                  <i className="icon icon-cross"></i>
                </button>
              </div>
            </div>
            <div className="eds-form ">
              <div className="row form-body mb-0">
                <div className="column sm-12 md-12 lg-6">
                  <div className="form-group">
                    <div className="form-element">
                      <label htmlFor="firstName" className="required">
                        First Name
                      </label>
                      <input
                        id="firstName"
                        type="text"
                        name="firstName"
                        className="validation"
                        placeholder="Enter First Name"
                        // pattern="^[a-zA-Z0-9 ]+$"
                        value={getFirstName}
                        onChange={(event) => {
                          setFirstName(event.target.value);
                        }}
                      />
                      <span className="validation-msg">
                        Only alphabets are allowed
                      </span>
                    </div>
                    <div className="form-element">
                      <label htmlFor="lastName" className="required">
                        Last Name
                      </label>
                      <input
                        id="lastName"
                        type="text"
                        name="lastName"
                        className="validation"
                        placeholder="Enter Last Name"
                        // pattern="^[a-zA-Z0-9 ]+$"
                        value={getLastName}
                        onChange={(event) => {
                          setLastName(event.target.value);
                        }}
                      />
                      <span className="validation-msg">
                        Only alphabets are allowed
                      </span>
                    </div>
                    <div className="form-element">
                      <label htmlFor="phoneNo">
                        Phone Number
                      </label>
                      <input
                        id="phoneNo"
                        type="tel"
                        name="phoneNo"
                        className="validation"
                        placeholder="Enter Phone Number"
                        value={getPhoneNo}
                        onChange={(event) => {
                          setPhoneNo(event.target.value);
                        }}
                      />
                      <span className="validation-msg">Only numbers are allowed</span>
                    </div>
                    <div className="form-element">
                      <label htmlFor="alternatephoneNo">
                        Emergency Phone Number
                      </label>
                      <input
                        id="alternatephoneNo"
                        type="tel"
                        name="alternatephoneNo"
                        className="validation"
                        placeholder="Enter Emergency Phone Number"
                        value={getAlternatePhoneNo}
                        onChange={(event) => {
                          setAlternatePhoneNo(event.target.value);
                        }}
                      />
                      <span className="validation-msg">Only numbers are allowed</span>
                    </div>

                  </div>
                </div>
                <div className="column sm-12 md-12 lg-6" style={{ borderRight: 0 }}>
                  <div className="form-group">

                    <div className="form-element">
                      <label htmlFor="email" className="required">
                        Email
                      </label>
                      <input
                        id="email"
                        type="text"
                        name="email"
                        className="validation"
                        placeholder="Enter Email Address"
                        value={getEmail}
                        onChange={handleEmail}
                        disabled={getUserRoles.type === "Save"}
                      />

                      <span className="validation-msg">Enter valid email</span>
                    </div>



                    <div className="form-element">
                      <label htmlFor="country" className="required">
                        Country
                      </label>
                      <EDSCustomMultiselect
                        setStatusOption={setCountry}
                        formDetailsUG={contriesDetails}
                        defaultValues={
                          getUserRoles.user?.country
                            ? [
                              `${getUserRoles.user.countryCode || ""}-${getUserRoles.user.country
                              }`,
                            ]
                            : []
                        }
                        filterName="country"
                        dataType="input-single"
                      // disabled={
                      //   getUserRoles.type === "Save" &&
                      //   getUserRoles.user?.country?.length &&
                      //   getUserRoles.user.countryCode
                      // }
                      />
                    </div>
                    <div className="form-element">
                      <label htmlFor="company" className="required">
                        Company
                      </label>
                      <EDSCustomMultiselect
                        setStatusOption={setCompany}
                        formDetailsUG={
                          getFilterList?.company?.map((company) => ({
                            formId: company,
                            form_name: company,
                          })) || []
                        }
                        defaultValues={
                          getUserRoles.user?.company
                            ? [getUserRoles.user.company]
                            : []
                        }
                        filterName="company"
                        dataType="input-single"
                      // disabled={
                      //   getUserRoles.type === "Save" &&
                      //   getUserRoles.user?.company.length
                      // }
                      />
                    </div>
                    <div className="form-element">
                      <label htmlFor="roles" className="required">
                        Roles<i className="icon icon-info-solid roleInfo" style={{ cursor: 'pointer' }} onClick={handleRolesInfo}></i>
                      </label>
                      <MultiselectDropDown
                        formName="roles"
                        setStatusOption={setRoles}
                        formDetailsUG={
                          getFilterList?.roles?.map((role) => ({
                            formId: role,
                            form_name: role,
                          })) || []
                        }
                        defaultValues={getRoles}
                      />
                    </div>
                  </div>
                </div>
                <div className="column sm-12 mt-lg">
                  {getLoading.updateCall ? (
                    <Loading />
                  ) : (
                    getStatusMessage &&
                    getStatusMessage !== "Successfull" && (
                      <span className="text-md color-red">
                        <i className="icon icon-failed mr-sm"></i>
                        {getStatusMessage}
                      </span>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="bottom">
              <button className="btn" onClick={onCancel}>
                Cancel
              </button>
              <button
                className="btn primary"
                disabled={
                  !(
                    getFirstName &&
                    getLastName &&
                    isValidEmail &&
                    getCountry.length &&
                    getCompany.length &&
                    getRoles.length
                  )
                }
                onClick={handleAddUserSubmit}
              >
                {getUserRoles.type}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
