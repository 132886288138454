import React, { useEffect } from "react";
import { ColorScale, HorizontalBarChartStacked } from "@eds/vanilla";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import Loading from "../../../components/Loading/Loading";
import { useAuditsByAuditTypeMutation } from "../../../redux/services/sitewalksAnalyticsApi";
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";

export const AuditsByAuditType = ({
  param,
  isAutoCall,
  getAnalyticsGraphsToggle,
  setAnalyticsGraphsToggle,
  validationMsg,
  validateFilterValues,
}) => {
  const [
    auditsByAuditType,
    { data: { data, message } = {}, isLoading, error },
  ] = useAuditsByAuditTypeMutation();

  const errorMessage =
    validationMsg ||
    (message !== "Success" && message) ||
    (error && "Internal Server Error. Please try again");

  const handleIsOpen = (event) => {
    event.preventDefault();

    const isValid = validateFilterValues("auditsByAuditType");

    isValid &&
      !getAnalyticsGraphsToggle.auditsByAuditType &&
      auditsByAuditType(JSON.stringify(param));

    setAnalyticsGraphsToggle((val) => ({
      ...val,
      auditsByAuditType: !val.auditsByAuditType,
    }));
  };

  useEffect(() => {
    const isValid = validateFilterValues("auditsByAuditType");

    isValid &&
      isAutoCall &&
      getAnalyticsGraphsToggle.auditsByAuditType &&
      auditsByAuditType(JSON.stringify(param));
  }, [isAutoCall]);

  useEffect(() => {
    if (Object.keys(data || {})?.length) {
      const parsedData = JSON.parse(data || "{}");

      const chartData = (() => {
        const locations = param.region || [];
        const groupByType = Object.groupBy(
          parsedData,
          ({ audit_type }) => audit_type
        );

        const formatedData = {
          common: locations,
          series: Object.entries(groupByType).map(([name, val]) => {
            const values = locations.map(
              (location) =>
                val.find((obj) => obj.location === location)?.count || 0
            );
            return {
              name,
              values,
            };
          }),
        };

        return formatedData;
      })();

      const chartHeight = chartData.common.length * 60;
      const mycolorScale = new ColorScale({ reverse: true });

      const chart = new HorizontalBarChartStacked({
        element: document.getElementById("auditsByAuditType-graph"),
        data: chartData,
        x: { unit: "Count" },
        height: chartHeight,
        colorScale: mycolorScale,
      });

      mycolorScale.init();

      chart.init();

      return () => {
        chart.destroy();
      };
    }
  }, [data]);

  return (
    <li
      tabIndex={6}
      className={`tile mb-sm accordion-item ${
        getAnalyticsGraphsToggle?.auditsByAuditType ? "opened" : ""
      }`}
    >
      <div className="title stickyTileHeader" onClick={handleIsOpen}>
        <div className="header p-sm">
          <div className="left">Audit Count by Audit Type</div>
        </div>
      </div>
      <div className="content">
        {isLoading && <Loading />}

        {errorMessage && (
          <AlertEDS errMsg={errorMessage} status_code={201} close={false} />
        )}

        {Object.keys(data || {})?.length && !errorMessage ? (
          <>
            <CustomGraphActions
              nodeId={"auditsByAuditType-graph"}
              fileName={"AuditsByAuditType-Graph"}
              data={data || []}
            />
            <div id="auditsByAuditType-graph"></div>
          </>
        ) : null}
      </div>
    </li>
  );
};
