import React, { useEffect } from "react";
import { ColorScale, HorizontalBarChartStacked } from "@eds/vanilla";
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";

export const CommentsNoCommentsGraph = ({ CommentsNoCommentsData}) => {


    useEffect(() => {
        const data = {
          common: CommentsNoCommentsData?.map((obj) => obj?.Category),
          series: [
            {
              name: "Comment",
              values: CommentsNoCommentsData?.map((obj) => obj?.Comment),
            },
            {
              name: "No-comment",
              values: CommentsNoCommentsData?.map((obj) => obj?.Nocomment),
            },
          ],
        };
        const chartHeight = data?.common?.length * 40;
        const mycolorScale = new ColorScale({ reverse: true });
        const chart = new HorizontalBarChartStacked({
          element: document.getElementById("commentsNoCommentsGraph"),
          data: data,
          x: { unit: "Count" },
          height: chartHeight,
          colorScale: mycolorScale,
        });
        mycolorScale.init();
        chart.init();
        return () => {
          chart.destroy();
          let element = document.getElementById(
            "commentsNoCommentsGraph"
          );
          while (element?.firstChild) {
            element.removeChild(element.firstChild);
          }
        };
      }, [CommentsNoCommentsData]);

    return (
        <>
            <CustomGraphActions
                nodeId={"commentsNoCommentsGraph"}
                fileName={"Comments_Nocomments_"}
                data={CommentsNoCommentsData}
            />
            <div id="commentsNoCommentsGraph"></div>
        </>
    );
};