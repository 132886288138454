import React, { Suspense, useEffect } from "react";
import { Breadcrumb } from "@eds/vanilla";
import { Routes, useLocation, useNavigate, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import AIImageUpload from "./AIImageUpload";

export default function AIInference() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const commonCardData = [
    {
      title: "PPE Detection",
      subtitle: "AI model to detect PPEs",
      id: "1",
      path: "/ppe-detection",
    },
    {
      title: "Hazard Identification",
      subtitle: "AI model to identify Hazards",
      id: "2",
      path: "/hazard-identification",
    },
  ];

  useEffect(() => {
    const title =
      commonCardData.find((val) => "/ai-services" + val.path === pathname)
        ?.title || "";
    const element = document.getElementById("config-breadcrumb-action");
    if (element && title) {
      const breadcrumb = new Breadcrumb(element);
      breadcrumb.init([
        {
          title: "AI Services",
          action: () => {
            navigate("/ai-services");
          },
        },
        {
          title: title,
        },
      ]);
      return () => {
        breadcrumb.destroy();
      };
    }
  }, [pathname]);

  const ConfigurationCards = () => {
    return (
      <>
        <div className="tile">
          <div className="eds-cards-list">
            {commonCardData.map((card) => (
              <div
                className="card"
                key={card.id}
                onClick={() => {
                  navigate(`/ai-services${card.path}`, {
                    state: { title: card.title },
                  });
                }}
              >
                <div className="header">
                  <div className="left">
                    <div className="title">{card.title}</div>
                    <div className="subtitle">{card.subtitle}</div>
                  </div>
                  <i className="icon icon-arrow-right"></i>
                </div>
              </div>
            ))}
            <br />
          </div>
          <div className="header" style={{ marginTop: "15px" }}>
            <div
              className="left"
              style={{
                marginTop: "-10px",
                marginLeft: "7px",
              }}
            >
              <div>
                <span className="disclaimer-text">
                  Disclaimer:
                </span>
                <span className="offWhite">
                  {" "}
                  This feature is offered for experimentation purposes only. It
                  should not be used to draw decisive conclusions about the
                  environment.
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
      <>
        <div>
          <div className="breadcrumb m-lg" id="config-breadcrumb-action"></div>
        </div>
        <Routes>
          <Route path="/" element={<ConfigurationCards />}></Route>
          <Route
            path="/hazard-identification"
            element={
              <Suspense fallback={<Loading />}>
                <AIImageUpload
                  commonCardData={commonCardData}
                  pathname={pathname}
                />
              </Suspense>
            }
          />
          <Route
            path="/ppe-detection"
            element={
              <Suspense fallback={<Loading />}>
                <AIImageUpload
                  commonCardData={commonCardData}
                  pathname={pathname}
                />
              </Suspense>
            }
          />
        </Routes>
      </>
  );
}
