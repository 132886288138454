import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Resizer from "react-image-file-resizer";
import {
  useImageUrlListFormMutation,
  useGetHazardAIDataMutation,
  useGetPpeAIDataMutation,
} from "../../redux/services/AIinferenceApi";
import { GroupButton } from "@eds/vanilla";
import ExampleOfAPersonWearingAllPPE from "../../asset/Icons/ExampleOfAPersonWearingAllPPE.JPEG";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../components/Loading/Loading";
import AIImageDialog from "./AIImageDialog";
export default function AIImageUpload({ commonCardData, pathname }) {
  const canvasRef = useRef(null);
  const ImageToken = useSelector(
    (state) => state.loggedInUser.user.profilePicture
  );
  const SasToken = ImageToken.indexOf("?");
  const querystring = ImageToken.substring(SasToken);
  const [hazardsdetected, sethazardsdetected] = useState(null);
  const [
    submitUrlFromList,
    { data: imageData, isLoading: imageLoading, error: imageError },
  ] = useImageUrlListFormMutation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isImageUpload, setIsImageUpload] = useState(true);
  const [
    getHazardAIData,
    { data: hazardData, isLoading: hazardLoading, error: hazardError },
  ] = useGetHazardAIDataMutation();
  const [
    getPpeAIData,
    { data: ppeData, isLoading: ppeLoading, error: ppeError },
  ] = useGetPpeAIDataMutation();

  const [uploadedImage, setuploadedImage] = useState(null);
  const findTitleByPathname = (commonCardData, pathname) => {
    const matchedCard = commonCardData.find((card) =>
      pathname.endsWith(card.path)
    );
    return matchedCard ? matchedCard.title : null;
  };

  const handleFileUpload = (event) => {
    const fileInput = event[0];
    if (!fileInput) {
      return;
    }
    const maxSize = 4 * 1024 * 1024;

    if (fileInput.size > maxSize) {
      try {
        Resizer.imageFileResizer(
          fileInput,
          1200,
          1200,
          "JPEG",
          100,
          0,
          (resizedImage) => {
            const resizedFile = new File([resizedImage], fileInput.name);
            const formData = new FormData();
            formData.append("files", resizedFile);
            formData.append("section_names", "AI_Tab_PPE");
            formData.append("upload_type", "JHA");
            formData.append("reportId", uuidv4());
            submitUrlFromList(formData);
          },
          "file",
          800,
          800
        );
      } catch (error) {
        console.error("Error resizing image:", error);
      }
    } else {
      const formData = new FormData();
      formData.append("files", fileInput);
      formData.append("section_names", "AI_Tab_PPE");
      formData.append("upload_type", "JHA");
      formData.append("reportId", uuidv4());
      submitUrlFromList(formData);
    }
  };

  const drawBoundingBoxes = (data, image) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(image, 0, 0, canvas.width, canvas.height);
    const detectionColors = ["#DC2D37"];
    let colorIndex = 0;
    Object.entries(data).forEach(([key, value]) => {
      if (value.boundingBox && value.boundingBox.length > 0) {
        const box = value.boundingBox[0];
        if (
          typeof box.left !== "undefined" &&
          typeof box.top !== "undefined" &&
          typeof box.width !== "undefined" &&
          typeof box.height !== "undefined"
        ) {
          const boxColor = detectionColors[colorIndex % detectionColors.length];
          context.beginPath();
          context.rect(
            box.left * canvas.width,
            box.top * canvas.height,
            box.width * canvas.width,
            box.height * canvas.height
          );
          context.lineWidth = 3;
          context.strokeStyle = boxColor;
          context.stroke();
          const fontSize =
            Math.min(box.width * canvas.width, box.height * canvas.height) *
            0.1;
          context.font = `bold ${fontSize}px Arial`;
          const textWidth = context.measureText(key).width;
          const textHeight = fontSize + 4;
          context.fillStyle = boxColor;
          context.fillRect(
            box.left * canvas.width - 1,
            box.top * canvas.height - textHeight,
            textWidth + 10,
            textHeight
          );
          context.fillStyle = "#FFF";
          const textX = box.left * canvas.width + 5;
          const textY = box.top * canvas.height - 5;
          context.fillText(key, textX, textY);
          colorIndex++;
        }
      }
    });
  };

  useEffect(() => {
    if (hazardsdetected && uploadedImage) {
      const image = new Image();
      image.src = uploadedImage;
      image.onload = () => drawBoundingBoxes(hazardsdetected, image);
    }
  }, [hazardsdetected, uploadedImage]);

  useEffect(() => {
    if (imageData) {
      const imageUrl = imageData.data[0];
      if (imageLoading) {
        setuploadedImage(null);
      }
      setuploadedImage(imageUrl + querystring);
      if (findTitleByPathname(commonCardData, pathname) === "PPE Detection") {
        sethazardsdetected(null);
        getPpeAIData({ pic_url: imageUrl });
      } else if (
        findTitleByPathname(commonCardData, pathname) ===
        "Hazard Identification"
      ) {
        sethazardsdetected(null);
        getHazardAIData({ pic_url: imageUrl });
      }
    }
  }, [imageData, getHazardAIData, getPpeAIData]);

  useEffect(() => {
    const btnGroupDOM = document.querySelector("#btnGroupH");

    if (btnGroupDOM) {
      const buttonGroup = new GroupButton(btnGroupDOM);
      buttonGroup.init();
    }
  });
  useEffect(() => {
    if (hazardData) {
      sethazardsdetected(hazardData.data);
    } else if (ppeData) {
      sethazardsdetected(ppeData.data);
    }
  }, [hazardData, ppeData]);

  const renderHazard = (key, value) => {
    if (Array.isArray(value)) {
      return value.map((item, index) => (
        <div key={`${key}_${index}`}>
          {item.hazard_identified === "Y" ? (
            <>
              <span style={{ color: "green" }}>✔</span>
              <span style={{ color: "green", marginLeft: "3px" }}>
                {item.hazard}
              </span>
            </>
          ) : (
            <>
              <i
                className="icon icon-triangle-warning"
                style={{ color: "red" }}
              ></i>
              <span style={{ color: "red", marginLeft: "1.5px" }}>
                {item.hazard}
              </span>
            </>
          )}
        </div>
      ));
    } else {
      return (
        <div key={key}>
          {value.hazard_identified === "Y" ? (
            <>
              {/* <i className="icon icon-check" style={{ color: "green" }}></i> */}
              <span style={{ color: "green" }}>✔</span>
              <span style={{ color: "green", marginLeft: "3px" }}>
                {value.hazard}
              </span>
            </>
          ) : (
            <>
              <i
                className="icon icon-triangle-warning"
                style={{ color: "red" }}
              ></i>
              {/* <span style={{ color: "red" }}>✘</span> */}
              <span style={{ color: "red", marginLeft: "1.5px" }}>
                {value.hazard}
              </span>
            </>
          )}
        </div>
      );
    }
  };

  return (
    <>
      <div className="row" style={{ marginTop: "-10px" }}>
        {!uploadedImage && imageLoading && <Loading />}
        <div className="tile md-4 sm-12 lg-5 ">
          <div className="header">
            <div className="left">
              <div className="title">
                {findTitleByPathname(commonCardData, pathname)}
              </div>
            </div>
            <div className="right">
              <div className="btn-group horizontal" id="btnGroupH">
                <button
                  className="btn icon-btn"
                  onClick={() => setIsImageUpload(true)}
                >
                  <i className="icon icon-image"></i>
                  <i
                    style={{
                      marginLeft: "2px",
                      fontFamily: "Ericsson Hilda, Helvetica, sans-serif",
                    }}
                  >
                    Image
                  </i>
                </button>
                <button
                  className="btn icon-btn"
                  onClick={() => {
                    setIsImageUpload(false);
                    setuploadedImage(null);
                  }}
                >
                  <i className="icon icon-video-play"></i>
                  <i
                    style={{
                      marginLeft: "2px",
                      fontFamily: "Ericsson Hilda, Helvetica, sans-serif",
                    }}
                  >
                    Video
                  </i>
                </button>
              </div>
            </div>
          </div>
          {isImageUpload ? (
            <>
              <div className="content">
                <div className="column">
                  <div
                    className="card drag-and-drop"
                    style={{ marginTop: "-13px" }}
                  >
                    <div className="header">
                      <i
                        className="icon icon-robot-head"
                        style={{ marginRight: "4px" }}
                      ></i>
                      <div className="left">Powered by AI</div>
                    </div>
                    <div className="content">
                      <p>Choose file(s) to upload</p>
                      <form
                        className="drag-and-drop-area"
                        onDragOver={(event) => {
                          event.preventDefault();
                        }}
                        onDrop={(event) => {
                          event.preventDefault();
                          handleFileUpload(event.dataTransfer.files);
                        }}
                      >
                        <i className="icon icon-upload"></i>
                        <p style={{ marginTop: "-13px" }}>
                          Drag & drop your file(s) here or{" "}
                          <label>
                            select to browse
                            <input
                              type="file"
                              multiple
                              accept="image/png, image/gif, image/jpeg"
                              onChange={(event) => {
                                handleFileUpload(event.target.files);
                              }}
                            />
                          </label>
                        </p>
                        <p>
                          Supported formats: png, jpeg
                          <br />
                        </p>
                      </form>
                      <div className="header header-files hidden">
                        <div className="left">Uploaded files</div>
                      </div>
                      <div className="content content-files">
                        <div className="file-pills"></div>
                        <table className="upload-table"></table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              {findTitleByPathname(commonCardData, pathname) ===
              "PPE Detection" ? (
                <a
                  href={ExampleOfAPersonWearingAllPPE}
                  target="_blank"
                  rel="noreferrer"
                  style={{ outlineStyle: "none" }}
                >
                  Example Image
                </a>
              ) : null}{" "}
            </>
          ) : (
            <div className="content featureCome">
                <i className="icon icon-time"></i> <p>Feature is Coming Soon.
              </p>
            </div>
          )}
        </div>
        {uploadedImage && isImageUpload && (
          <div className="tile md-5 sm-12 lg-3">
            <div className="header">
              <div className="left">
                <div className="title">Uploaded Image</div>
              </div>

              {uploadedImage && (
                <div className="right">
                  <button
                    className="btn-icon"
                    data-close="true"
                    onClick={() => setuploadedImage(null)}
                  >
                    <i className="icon icon-cross"></i>
                  </button>
                </div>
              )}
            </div>
            {imageLoading ? (
              <Loading />
            ) : (
              <>
                <div className="content">
                  <div className="column">
                    <canvas
                      ref={canvasRef}
                      style={{
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsDialogOpen(true)}
                    ></canvas>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        {uploadedImage && isImageUpload && (
          <div className="tile md-3 sm-12 lg-3">
            <div className="header">
              <div className="left">
                <div className="title">AI Insights</div>
              </div>
            </div>
            {hazardLoading || ppeLoading ? (
              <Loading />
            ) : (
              <div className="content" style={{ marginTop: "5px" }}>
                <div className="column">
                  {hazardsdetected && (
                    <>
                      {Object.entries(hazardsdetected).map(([key, value]) =>
                        renderHazard(key, value)
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="header" style={{ marginTop: "15px" }}>
        <div
          className="left"
          style={{
            marginTop: "-10px",
          }}
        >
          <div>
            <span
              className="disclaimer-text"
              style={{ whiteSpace: "nowrap", marginLeft: "15px" }}
            >
              Disclaimer:
            </span>
            <span
              style={{
                color: "#888",
                alignItems: "center",
              }}
            >
              {" "}
              This feature is offered for experimentation purposes only. It
              should not be used to draw decisive conclusions about the
              environment.
            </span>
          </div>
        </div>
      </div>
      {isDialogOpen && (
        <AIImageDialog
          setIsDialogOpen={setIsDialogOpen}
          uploadedImage={uploadedImage}
          canvasRef={canvasRef}
          hazardsdetected={hazardsdetected}
        />
      )}
    </>
  );
}
