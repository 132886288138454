import React, { useEffect } from "react";
import { ColorScale, HorizontalBarChart } from "@eds/vanilla";
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";
export default function SiteWalkGraph2({ site_walk_name }) {
  useEffect(() => {
    const data = {
      common: site_walk_name?.map((obj) => obj?.Auditor),
      series: [
        {
          name: `Count by Auditor`,
          values: site_walk_name?.map((obj) => obj?.Count),
        },
      ],
    };
    const chartHeight = data?.common?.length * 40;
    const mycolorScale = new ColorScale({ reverse: true });
    const chart = new HorizontalBarChart({
      element: document.getElementById("totalSiteWalkCompletionCountByName"),
      data: data,
      x: { unit: "Count" },
      height: chartHeight,
      colorScale: mycolorScale,
    });
    mycolorScale.init();
    chart.init();
    return () => {
      chart.destroy();
      let element = document.getElementById(
        "totalSiteWalkCompletionCountByName"
      );
      while (element?.firstChild) {
        element.removeChild(element.firstChild);
      }
    };
  }, [site_walk_name]);

  return (
    <>
      <CustomGraphActions
        nodeId={"totalSiteWalkCompletionCountByName"}
        fileName={"Total_SiteWalk_Completion_CountByAuditor"}
        data={site_walk_name}
      />
      <div id="totalSiteWalkCompletionCountByName"></div>
    </>
  );
}
