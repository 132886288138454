import { URL } from "../utils/Constant";
import _ from "lodash";

export const getSiteWalkDataAPi = async (
  param,
  setLoading,
  setErrMsg,
  setSiteWalkData,
  headersList
) => {
  setLoading((val) => ({ ...val, siteWalkbyRegion: true }));
  const url = URL.ERICSION.SITE_WALK_COUNT_URL;
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(param),
      headers: headersList,
    });
    if (resp.ok) {
      const data = await resp.json();
      const siteWalkData = JSON.parse(
        _.isEmpty(data?.data) ? "{}" : data?.data
      );
      if (
        data.status_code === 201 ||
        Object.values(siteWalkData?.location || {}).length < 1
      ) {
        setErrMsg({ siteWalkbyRegion: "No data available in this date range" });
        setSiteWalkData([]);
      } else {
        const countGraph = siteWalkData["count"];
        const statusGraph = siteWalkData["location"];
        let total = 0;
        const formedData = [];
        for (const property in statusGraph) {
          formedData.push({
            location: statusGraph[property],
            Count: countGraph[property],
          });
          total += countGraph[property];
        }
        formedData.push({
          location: "Total",
          Count: total,
        });
        formedData.sort((a, b) => a?.Count - b?.Count);
        setErrMsg((val) => ({ ...val, siteWalkbyRegion: data.message }));
        setSiteWalkData(formedData);
      }
      setLoading((val) => ({ ...val, siteWalkbyRegion: false }));
    } else {
      setSiteWalkData([]);
      setErrMsg((val) => ({
        ...val,
        siteWalkbyRegion: "Internal Server Error. Please try again",
      }));
      setLoading((val) => ({ ...val, siteWalkbyRegion: false }));
    }
  } catch {
    setSiteWalkData([]);
    setErrMsg((val) => ({
      ...val,
      siteWalkbyRegion: "Internal Server Error. Please try again",
    }));
    setLoading((val) => ({ ...val, siteWalkbyRegion: false }));
  }
};

export const getSiteWalkData2APi = async (
  param,
  setLoading,
  setErrMsg,
  setSiteWalkData2,
  headersList
) => {
  setLoading((val) => ({ ...val, siteWalkbyName: true }));
  const url = URL.ERICSION.SITE_WALK_COUNT2_URL;
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(param),
      headers: headersList,
    });
    if (resp.ok) {
      const data = await resp.json();
      const siteWalkData = JSON.parse(
        _.isEmpty(data?.data) ? "{}" : data?.data
      );
      if (
        data.status_code === 201 ||
        Object.values(siteWalkData?.name).length < 1
      ) {
        setErrMsg((val) => ({
          ...val,
          siteWalkbyName: "No data available in this date range",
        }));
        setSiteWalkData2([]);
      } else {
        const countGraph = siteWalkData?.count;
        const statusGraph = siteWalkData?.name;
        const formedData = [];
        for (const property in statusGraph) {
          formedData.push({
            Auditor: statusGraph[property],
            Count: countGraph[property],
          });
        }
        formedData.sort((a, b) => a?.Count - b?.Count);
        setErrMsg((val) => ({ ...val, siteWalkbyName: data.message }));
        setSiteWalkData2(formedData);
      }
      setLoading((val) => ({ ...val, siteWalkbyName: false }));
    } else {
      setSiteWalkData2([]);
      setErrMsg((val) => ({
        ...val,
        siteWalkbyName: "Internal Server Error. Please try again",
      }));
      setLoading((val) => ({ ...val, siteWalkbyName: false }));
    }
  } catch {
    setSiteWalkData2([]);
    setErrMsg((val) => ({
      ...val,
      siteWalkbyName: "Internal Server Error. Please try again",
    }));
    setLoading((val) => ({ ...val, siteWalkbyName: false }));
  }
};

export const getSiteWalkDataActionItemsByRegion = async (
  param,
  setLoading,
  setErrMsg,
  setActionItemsData,
  headersList
) => {
  setLoading((val) => ({ ...val, siteWalkbyActionItems: true }));
  const url = URL.ERICSION.SITE_WALK_ACTION_ITEMS;
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(param),
      headers: headersList,
    });
    if (resp.ok) {
      const data = await resp.json();
      const graphData = data?.data;
      if (data.status_code === 201 || _.isEmpty(graphData)) {
        setActionItemsData([]);
        setErrMsg((val) => ({
          ...val,
          siteWalkbyActionItems: "No data available in this date range",
        }));
      } else {
        const closeData = JSON.parse(graphData?.close_data || "{}");
        const openData = JSON.parse(graphData?.open_data || "{}");
        const openRegion = Object.values(openData?.region);
        const openCount = Object.values(openData?.count);
        const closeRegion = Object.values(closeData?.region);
        const closeCount = Object.values(closeData?.count);
        const locations = [...new Set(openRegion.concat(closeRegion))];
        const formatedData = [];
        locations.forEach((location) => {
          const openIndex = openRegion.indexOf(location);
          const closeIndex = closeRegion.indexOf(location);
          formatedData.push({
            Region: location,
            OpenCount: openIndex < 0 ? 0 : openCount[openIndex],
            CloseCount: closeIndex < 0 ? 0 : closeCount[closeIndex],
          });
        });
        formatedData.sort(
          (a, b) =>
            a?.OpenCount + a?.CloseCount - (b?.OpenCount + b?.CloseCount)
        );
        setActionItemsData(formatedData);
        setErrMsg((val) => ({ ...val, siteWalkbyActionItems: data.message }));
      }
      setLoading((val) => ({ ...val, siteWalkbyActionItems: false }));
    } else {
      setActionItemsData([]);
      setErrMsg((val) => ({
        ...val,
        siteWalkbyActionItems: "Internal Server Error. Please try again",
      }));
      setLoading((val) => ({ ...val, siteWalkbyActionItems: false }));
    }
  } catch {
    setActionItemsData([]);
    setErrMsg((val) => ({
      ...val,
      siteWalkbyActionItems: "Internal Server Error. Please try again",
    }));
    setLoading((val) => ({ ...val, siteWalkbyActionItems: false }));
  }
};

export const getSiteWalkAllActionsDetailsByName = async (
  param,
  setLoading,
  setErrMsg,
  setActionItemsData,
  graphDetails,
  headersList
) => {
  if (graphDetails.isDataAvaialble) {
    if (graphDetails.name === "actionsByOwner") {
      setLoading((val) => ({ ...val, actionsByOwner: false }));
      setErrMsg((val) => ({
        ...val,
        actionsByOwner: val.actionsByAuditor,
      }));
    } else {
      setLoading((val) => ({ ...val, actionsByAuditor: false }));
      setErrMsg((val) => ({
        ...val,
        actionsByAuditor: val.actionsByOwner,
      }));
    }
    return;
  }
  if (graphDetails.name === "actionsByOwner") {
    setLoading((val) => ({ ...val, actionsByOwner: true }));
  } else {
    setLoading((val) => ({ ...val, actionsByAuditor: true }));
  }
  const url = URL.ERICSION.SITE_WALK_ALL_ACTIONS_DETTAILS;
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(param),
      headers: headersList,
    });
    if (resp.ok) {
      const data = await resp.json();
      if (data.status_code === 201 || _.isEmpty(data?.data)) {
        setActionItemsData({ actionsByOwner: [], actionsByCreator: [] });
      } else {
        const getOpenItemsByOwner = JSON.parse(
          data.data?.ownerOpen_data || "{}"
        );
        const getClosedItemsByOwner = JSON.parse(
          data.data?.ownerClose_data || "{}"
        );
        const getOpenItemsByCreator = JSON.parse(
          data.data?.creatorOpen_data || "{}"
        );
        const getClosedItemsByCreator = JSON.parse(
          data.data?.creatorClose_data || "{}"
        );
        const actionsByOwner = (function () {
          const openOwners = Object.values(getOpenItemsByOwner?.owner);
          const openCount = Object.values(getOpenItemsByOwner?.count);
          const closeOwners = Object.values(getClosedItemsByOwner?.owner);
          const closeCount = Object.values(getClosedItemsByOwner?.count);
          const locations = [...new Set(openOwners.concat(closeOwners))];
          const ownerFormatedData = [];
          locations.forEach((owner) => {
            const openIndex = openOwners.indexOf(owner);
            const closeIndex = closeOwners.indexOf(owner);
            ownerFormatedData.push({
              Name: owner,
              OpenCount: openIndex < 0 ? 0 : openCount[openIndex],
              CloseCount: closeIndex < 0 ? 0 : closeCount[closeIndex],
            });
          });
          ownerFormatedData.sort(
            (a, b) =>
              a?.OpenCount + a?.CloseCount - (b?.OpenCount + b?.CloseCount)
          );
          return ownerFormatedData;
        })();
        const actionsByCreator = (function () {
          const openAuditors = Object.values(getOpenItemsByCreator?.creator);
          const openCount = Object.values(getOpenItemsByCreator?.count);
          const closeAuditors = Object.values(getClosedItemsByCreator?.creator);
          const closeCount = Object.values(getClosedItemsByCreator?.count);
          const locations = [...new Set(openAuditors.concat(closeAuditors))];
          const formatedData = [];
          locations.forEach((auditor) => {
            const openIndex = openAuditors.indexOf(auditor);
            const closeIndex = closeAuditors.indexOf(auditor);
            formatedData.push({
              Name: auditor,
              OpenCount: openIndex < 0 ? 0 : openCount[openIndex],
              CloseCount: closeIndex < 0 ? 0 : closeCount[closeIndex],
            });
          });
          formatedData.sort(
            (a, b) =>
              a?.OpenCount + a?.CloseCount - (b?.OpenCount + b?.CloseCount)
          );
          return formatedData;
        })();

        setActionItemsData({ actionsByOwner, actionsByCreator });
      }
      if (graphDetails.name === "actionsByOwner") {
        setLoading((val) => ({ ...val, actionsByOwner: false }));
        setErrMsg((val) => ({
          ...val,
          actionsByOwner: data.message,
        }));
      } else {
        setLoading((val) => ({ ...val, actionsByAuditor: false }));
        setErrMsg((val) => ({
          ...val,
          actionsByAuditor: data.message,
        }));
      }
    } else {
      setActionItemsData({});
      if (graphDetails.name === "actionsByOwner") {
        setLoading((val) => ({ ...val, actionsByOwner: false }));
        setErrMsg((val) => ({
          ...val,
          actionsByOwner: "Internal Server Error. Please try again",
        }));
      } else {
        setLoading((val) => ({ ...val, actionsByAuditor: false }));
        setErrMsg((val) => ({
          ...val,
          actionsByAuditor: "Internal Server Error. Please try again",
        }));
      }
    }
  } catch {
    setActionItemsData({});
    if (graphDetails.name === "actionsByOwner") {
      setLoading((val) => ({ ...val, actionsByOwner: false }));
      setErrMsg((val) => ({
        ...val,
        actionsByOwner: "Internal Server Error. Please try again",
      }));
    } else {
      setLoading((val) => ({ ...val, actionsByAuditor: false }));
      setErrMsg((val) => ({
        ...val,
        actionsByAuditor: "Internal Server Error. Please try again",
      }));
    }
  }
};

export const getSiteWalkResponsesData = async (
  param,
  setLoading,
  setErrMsg,
  setActionItemsData,
  headersList
) => {
  setLoading((val) => ({ ...val, responsesByRegion: true }));
  const url = URL.ERICSION.SITE_WALK_SAFE_UNSAFE_GROUPED_BY_REGION;
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(param),
      headers: headersList,
    });
    if (resp.ok) {
      const data = await resp.json();
      const safeResponsesData = JSON.parse(data?.data?.safeResponses || "{}");
      const unsafeResponsesData = JSON.parse(
        data?.data?.unsafeResponses || "{}"
      );
      if (data.status_code === 201 || _.isEmpty(data.data)) {
        setErrMsg((val) => ({
          ...val,
          responsesByRegion: "No data available in this date range",
        }));
        setActionItemsData([]);
      } else {
        const safeCategories = Object.values(safeResponsesData?.category);
        const safeCount = Object.values(safeResponsesData?.count);
        const unsafeCategories = Object.values(unsafeResponsesData?.category);
        const unsafeCount = Object.values(unsafeResponsesData?.count);
        const locations = [...new Set(safeCategories.concat(unsafeCategories))];
        const formatedData = [];
        locations.forEach((category) => {
          const safeIndex = safeCategories.indexOf(category);
          const unsafeIndex = unsafeCategories.indexOf(category);
          formatedData.push({
            Category: category,
            SafeCount: safeIndex < 0 ? 0 : safeCount[safeIndex],
            UnsafeCount: unsafeIndex < 0 ? 0 : unsafeCount[unsafeIndex],
          });
        });
        formatedData.reverse();
        setActionItemsData(formatedData);
        setErrMsg((val) => ({ ...val, responsesByRegion: data.message }));
      }
      setLoading((val) => ({ ...val, responsesByRegion: false }));
    } else {
      setActionItemsData([]);
      setErrMsg((val) => ({
        ...val,
        responsesByRegion: "Internal Server Error. Please try again",
      }));
      setLoading((val) => ({ ...val, responsesByRegion: false }));
    }
  } catch {
    setActionItemsData([]);
    setErrMsg((val) => ({
      ...val,
      responsesByRegion: "Internal Server Error. Please try again",
    }));
    setLoading((val) => ({ ...val, responsesByRegion: false }));
  }
};

export const getSiteWalkAuditCountData = async (
  param,
  setData,
  headersList
) => {
  setData({
    loading: true,
    error: "",
    data: [],
  });
  const url = URL.ERICSION.GET_AUDIT_COUNT_DATA;
  const response = { data: [], error: "" };
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(param),
      headers: headersList,
    });
    const data = await resp.json();
    response.data = data?.data || [];
    if (data.status_code === 200 && response.data.length < 1) {
      response.error = "No data available in this date range";
    } else {
      response.error =
        data.message || "Internal Server Error. Please try again";
    }
  } catch {
    response.error = "Internal Server Error. Please try again";
  }
  setData({
    loading: false,
    error: response.error,
    data: response.data,
  });
};

export const getSiteWalkAuditCountBySupplierData = async (
  param,
  setLoading,
  setErrMsg,
  setActionItemsData,
  headersList
) => {
  setLoading((val) => ({ ...val, auditCountBySupplier: true }));
  const url = URL.ERICSION.GET_ASP_SUPPLIER_DATA;
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(param),
      headers: headersList,
    });
    if (resp.ok) {
      const data = await resp.json();
      const countBySuppliersData = JSON.parse(
        _.isEmpty(data?.data) ? "{}" : data?.data
      );
      if (
        data.status_code === 201 ||
        Object.values(countBySuppliersData).length < 1
      ) {
        setActionItemsData([]);
      } else {
        const formatedData = [];
        Object.keys(countBySuppliersData)?.forEach((location) => {
          const supplierCount = countBySuppliersData?.[location]?.count || [];
          const supplierNames =
            countBySuppliersData?.[location]?.aspNames || [];
          supplierNames.forEach((val, ind) => {
            formatedData.push({
              Location: location,
              "Supplier Name": val,
              Count: supplierCount?.[ind] || 0,
            });
          });
        });
        setActionItemsData(formatedData);
      }
      setErrMsg((val) => ({ ...val, auditCountBySupplier: data.message }));
      setLoading((val) => ({ ...val, auditCountBySupplier: false }));
    } else {
      setErrMsg((val) => ({
        ...val,
        auditCountBySupplier: "Internal Server Error. Please try again",
      }));
      setActionItemsData([]);
      setLoading((val) => ({ ...val, auditCountBySupplier: false }));
    }
  } catch {
    setErrMsg((val) => ({
      ...val,
      auditCountBySupplier: "Internal Server Error. Please try again",
    }));
    setActionItemsData([]);
    setLoading((val) => ({ ...val, auditCountBySupplier: false }));
  }
};

export const getCommentsNoComments = async (
  param,
  setLoading,
  setErrMsg,
  setCommentsNoCommentsData,
  headersList
) => {
  setLoading((val) => ({ ...val, commentsNoComments: true }));
  const url = URL.ERICSION.ADD_COMMENT_GROUPED_REGION;
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(param),
      headers: headersList,
    });
    if (resp.ok) {
      const data = await resp.json();
      const commentResponses = JSON.parse(data?.data?.commentResponses || "{}");
      const nocommentResponses = JSON.parse(data?.data?.nocommentResponses || "{}");
      if (data.status_code === 201 || _.isEmpty(data.data)) {
        setErrMsg((val) => ({
          ...val,
          commentsNoComments: "No data available in this date range",
        }));
        setCommentsNoCommentsData([]);
      } else {
        const commentCategories = Object.values(commentResponses?.category);
        const commentCount = Object.values(commentResponses?.count);
        const nocommentCategories = Object.values(nocommentResponses?.category);
        const nocommentCount = Object.values(nocommentResponses?.count);
        const locations = [...new Set(commentCategories.concat(nocommentCategories))];
        const formatedData = [];
        locations.forEach((category) => {
          const comment = commentCategories.indexOf(category);
          const nocomment = nocommentCategories.indexOf(category);
          formatedData.push({
            Category: category,
            Comment: comment < 0 ? 0 : commentCount[comment],
            Nocomment: comment < 0 ? 0 : nocommentCount[comment],
          });
        });
        formatedData.reverse();
        
        setCommentsNoCommentsData(formatedData);
        setErrMsg((val) => ({ ...val, commentsNoComments: data.message }));
      }
      setLoading((val) => ({ ...val, commentsNoComments: false }));
    } else {
      setErrMsg((val) => ({
        ...val,
        commentsNoComments: "Internal Server Error. Please try again",
      }));
      setCommentsNoCommentsData([]);
      setLoading((val) => ({ ...val, commentsNoComments: false }));
    }
  } catch {
    setErrMsg((val) => ({
      ...val,
      commentsNoComments: "Internal Server Error. Please try again",
    }));
    setCommentsNoCommentsData([]);
    setLoading((val) => ({ ...val, commentsNoComments: false }));
  }
};
