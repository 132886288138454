import { Dialog } from "@eds/vanilla";
import { Table } from "@eds/vanilla";
import { exportToCSVMethod } from "../../../utils/helperMethod";
import { useState, useEffect, useContext } from "react";
import { domain_url } from "../../../utils/Constant";
import _ from "lodash";
import ModelReportPreview from "../../../components/Modal/ModelReportPreview";
export default function ChildReportPopup({
  getChildReportData,
  setChildReportPopData,
  setShowSubReportModal,
  headersList,
  callGenerateReport,
}) {
  const [totaltime_js, setTotaltimeJS] = useState(getChildReportData);
  const [Childreportdata, setChildreportdata] = useState("");
  const [previewpopup, setpreviewpopup] = useState({
    isOpen: false,
  });
  const [getData, setData] = useState();

  const onCloseHandler = () => {
    setShowSubReportModal(false);
    setChildReportPopData({});
  };

  useEffect(() => {
    const dialogs = document.querySelectorAll(".loneWorkerManagerList");
    if (dialogs) {
      Array.from(dialogs).forEach((dialogDOM) => {
        const dialog = new Dialog(dialogDOM);
        dialog.init();
      });
    }
  }, []);

  const _data = () => {
    let product = [];
    for (
      let tblData = Object.keys(totaltime_js["ReportID"]).length - 1;
      tblData >= 0;
      tblData--
    ) {
      // for (let tblData = 0 ; tblData <= Object.keys(loneworkerData['Email']).length - 1; tblData++) {
      product.push({
        "Report Type": totaltime_js["App Type"][tblData],
        Esw_unique_id: totaltime_js["esw_unique_id"][tblData],
        "Project ID": totaltime_js["Unique ID"][tblData],
        "Crew Lead": totaltime_js["Crew Lead"][tblData],
        "Additional Crew": totaltime_js["Crew Names"][tblData]?.join(", "),
        "Submitted Datetime (CST)": totaltime_js["Submitted Datetime"][tblData],
        "SNTP Datetime (CST)": totaltime_js["SNTP Datetime (CST)"][tblData],
        "Total time (mins)": totaltime_js["Total time"][tblData],
        Download: totaltime_js["ReportID"][tblData],
        Status: totaltime_js["Status"][tblData],
        "Site ID": totaltime_js["Site_ID"][tblData],
        Customer: totaltime_js["customer"][tblData],
        "Crew Lead Email": totaltime_js["email"][tblData],
        "Crew Lead Company Name": totaltime_js["company"][tblData],
        "AI Status": totaltime_js["ai_status"][tblData],
        "Climber Data": totaltime_js["climber_data"][tblData],
        State: totaltime_js["State"][tblData],
      });
    }
    return product;
  };

  useEffect(() => {
    if (!_.isEmpty(totaltime_js)) {
      const tableDOM = document.querySelector("#subReports");
      if (_.isEmpty(getData)) {
        const table = new Table(tableDOM, {
          data: _data(),
          columns: [
            {
              key: "Report Type",
              title: "Report Type",
              sort: "none",
            },
            {
              key: "Esw_unique_id",
              title: "Report ID",
              sort: "none",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<span class="tooltip pointer text-center" style="width:100%;"><div class="ellipsis">${data}</div><span class="message right">${data}</span></span>`;
              },
            },
            {
              key: "Project ID",
              title: "Project ID",
              sort: "none",
              cellClass: "text-wrap",
            },
            {
              key: "Crew Lead",
              title: "Crew Lead",
              sort: "none",
            },
            {
              key: "Additional Crew",
              title: "Additional Crew",
              sort: "none",
            },
            {
              key: "Submitted Datetime (CST)",
              title: "Submitted Datetime (CST)",
              sort: "none",
              cellClass: "Submitted-Datetime",
            },
            {
              key: "SNTP Datetime (CST)",
              title: "SNTP Datetime (CST)",
              sort: "none",
              cellClass: "SNTP-Datetime",
            },
            {
              key: "Total time (mins)",
              title: "Total time (mins)",
              sort: "none",
              cellClass: "text-center",
              cellStyle: "text-align: center",
            },
            {
              key: "Download",
              title: "View Details",
              cellClass: "text-center",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<span class="tooltip pointer text-center"><button class="btn icon-btn viewSubReport primary" index="${data[3]}" ><i class="icon icon-launch"></i></button><span class="message left">Review detailed report</span></span>`;
                td.querySelector("button.viewSubReport").addEventListener(
                  "click",
                  async (evt) => {
                    setpreviewpopup({ isOpen: true });
                    setChildreportdata(
                      await callGenerateReport(
                        `${domain_url}/show_report/${data}`,
                        headersList
                      )
                    );
                  }
                );
              },
            },
            {
              key: "Status",
              title: "Status",
              sort: "none",
              cellStyle: "text-align: center",
            },
          ],
          sortable: true,
        });
        table.init();
        setData(table);
      } else {
        getData.update(_data());
      }
    }
  }, [totaltime_js]);

  const callExport = () => {
    const csvData = [];
    const data = _data();
    data?.forEach((val) => {
      csvData.push({
        "Submitted Datetime (CST)": val?.["Submitted Datetime (CST)"],
        "Report Type": val?.["Report Type"],
        "Project ID": val?.["Project ID"],
        "Site ID": val?.["Site ID"],
        Customer: val?.["Customer"],
        "Crew Lead": val?.["Crew Lead"],
        "Crew Lead Email": val?.["Crew Lead Email"],
        "Crew Lead Company Name": val?.["Crew Lead Company Name"],
        "Additional Crew": !_.isEmpty(val?.["Additional Crew"])
          ? val["Additional Crew"]
          : "",
        "SNTP Datetime (CST)": val?.["SNTP Datetime (CST)"],
        "Total time (mins)": val?.["Total time (mins)"],
        "Link to item": domain_url + "/show_report/" + val?.["Download"],
        Status: val?.["Status"],
        "AI Status": val?.["AI Status"],
        "Climber Data": val?.["Climber Data"],
        State: val?.["State"],
        "Report ID": val?.["Esw_unique_id"],
      });
    });
    exportToCSVMethod(csvData, "Version Reports");
  };

  return (
    <>
      {previewpopup.isOpen ? (
        <ModelReportPreview
          setpreviewpopup={setpreviewpopup}
          Childreportdata={Childreportdata}
          setChildreportdata={setChildreportdata}
          setShowSubReportModal={setShowSubReportModal}
        />
      ) : (
        <div
          className="dialog subReportsPopup fullscreen show"
          data-trigger="#open-subReports"
          data-type="fullscreen"
        >
          <div className="content">
            <div className="top">
              <div className="title">Available Version</div>
              <div className="right">
                <button
                  className="btn-icon"
                  data-close="true"
                  onClick={() => onCloseHandler()}
                >
                  <i className="icon icon-cross"></i>
                </button>
              </div>
            </div>
            <div className="body">
              <div className="table-top">
                <div className="table-top-left">
                  <div className="table-info"></div>
                </div>
                <div className="table-top-right">
                  <div className="table-buttons">
                    <button
                      className="btn"
                      id="exportDataJHA-Reports"
                      onClick={() => callExport()}
                    >
                      Export
                    </button>
                  </div>
                  <div className="table-search">
                    <input
                      type="text"
                      className="with-icon"
                      placeholder="Search for..."
                    />
                    <div className="suffix icon-inside">
                      <i className="icon icon-search"></i>
                    </div>
                  </div>
                </div>
              </div>
              <table
                id="subReports"
                className="table dashed stickyHeader horizonatalScroll"
              ></table>
              <div className="table-bottom">
                <div className="pagination-group">
                  <ul className="pagination"></ul>
                  <div className="actions">
                    <label className="left">Go to</label>
                    <input type="number" min="1" />
                    <label className="left">Show</label>
                    <div className="select" data-type="single">
                      <button className="btn current-options">5</button>
                      <div className="options-list">
                        <div className="item active">5</div>
                        <div className="item">10</div>
                        <div className="item">15</div>
                        <div className="item">All</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
