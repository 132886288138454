import React, { useEffect } from "react";
import { ColorScale, HorizontalBarChartStacked } from "@eds/vanilla";
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";

export default function SafeResponsesUnsafeResponsesGraph({
  safeUnsafeResponsesData,
}) {
  useEffect(() => {
    const data = {
      common: safeUnsafeResponsesData?.map((obj) => obj?.Category),
      series: [
        {
          name: "Safe Responses",
          values: safeUnsafeResponsesData?.map((obj) => obj?.SafeCount),
        },
        {
          name: "Unsafe Responses",
          values: safeUnsafeResponsesData?.map((obj) => obj?.UnsafeCount),
        },
      ],
    };
    const chartHeight = data?.common?.length * 40;
    const mycolorScale = new ColorScale({ reverse: true });
    const chart = new HorizontalBarChartStacked({
      element: document.getElementById("safeResponsesUnsafeResponsesGraph"),
      data: data,
      x: { unit: "Count" },
      height: chartHeight,
      colorScale: mycolorScale,
    });
    mycolorScale.init();
    chart.init();
    return () => {
      chart.destroy();
      let element = document.getElementById(
        "safeResponsesUnsafeResponsesGraph"
      );
      while (element?.firstChild) {
        element.removeChild(element.firstChild);
      }
    };
  }, [safeUnsafeResponsesData]);

  return (
    <>
      <CustomGraphActions
        nodeId={"safeResponsesUnsafeResponsesGraph"}
        fileName={"SafeResponses_UnsafeResponses"}
        data={safeUnsafeResponsesData}
      />
      <div id="safeResponsesUnsafeResponsesGraph"></div>
    </>
  );
}
