import { NavLink } from "react-router-dom";
import { Favourites } from "../Favourites/Favourites";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { LastLoginTime } from "./LastLoginTime";
import { useSelector } from "react-redux";
import "./Home.scss"
import ifAward from "../../asset/Icons/if-award.png";
import redDotAward from "../../asset/Icons/red-dot-award.png";
import { useEffect } from "react";
import AppLinks from "./AppLinks";

export default function Home() {
  const policyStatus = useSelector((state) => state.loggedInUser.policyStatus);
  const enduserStatement = useSelector((state) => state.loggedInUser.enduserStatement);
  const lastLoginDateMessage = useSelector(
    (state) => state.loggedInUser.lastLoginDateMessage
  );

  useEffect(() => {
    setTimeout(() => { document.querySelector('.hero-left .description')?.classList.add('show') }, 500);
    setTimeout(() => { document.querySelector('.hero-left .p-button')?.classList.add('show')}, 500);
    setTimeout(() => { document.querySelector('.mp-section .awards-links')?.classList.add('show') }, 600);
    setTimeout(() => { document.querySelector('.hero-bg .hero-bg-layer-1')?.classList.add('show') }, 900);
    setTimeout(() => { document.querySelector('.hero-bg .hero-bg-layer-2')?.classList.add('show') }, 1200);
    setTimeout(() => { document.querySelector('.hero-bg .hero-bg-layer-3')?.classList.add('show') }, 1400);
  }, []);
  return (
    <div className="home">
      <section className="main-hero mp-section">
        <div className="column">
          <div className="hero-bg">
            <div className="hero-bg-layer-1" ></div>
            <div className="hero-bg-layer-2" ></div>
            <div className="hero-bg-layer-3" ></div>
          </div>
          <div className="row">
            <div className="column hero-left">
              <h1>Safe Worker</h1>
              <p className="description">Review Safety forms, Manage Safety Actions, Support Lone Workers, Manage users using this powerful real-time dashboard with large-scale Data Analytics capabilities.</p>
              <p className="p-button">
                <NavLink className="btn btn-secodary" to="/Get-Support">
                  <i className="icon icon-message-double"></i> Get Support
                </NavLink>
              </p>

            </div>
            <div className="awards-links" >
              <div><img alt="IF award" src={ifAward} /></div>
              <span></span>
              <div><img alt="IF award" src={redDotAward} /></div>
            </div>
          </div>
        </div>
      </section>







      {/* <div className="column sm-12 hero">
        <div className="row">
          <div className="column sm-12 xl-8 hero-left">
            <h1>Safe Worker</h1>
            <p className="description">
              Review Safety forms, Manage Safety Actions, Support Lone Workers,
              Manage users using this powerful real-time dashboard with
              large-scale Data Analytics capabilities.
            </p>
            <p className="p-button">
              <NavLink className="btn" to="/Get-Support">
                <i className="icon icon-message-double"></i> Get Support
              </NavLink>
            </p>
          </div>
          <div className="column sm-12 xl-6 layers">
            <div className="img-landing-layers"></div>
          </div>
        </div>
      </div> */}
      {sessionStorage.getItem("isLastLoginAPICalled") === "false" &&
        policyStatus === true &&
        lastLoginDateMessage === "Success" && <LastLoginTime />}
     {(!policyStatus || !enduserStatement) && <PrivacyPolicy />} 
      <AppLinks />
      <Favourites />
    </div>
  );
}
