import { Dialog } from "@eds/vanilla";
import { useEffect } from "react";
export default function AIImageDialog({
  setIsDialogOpen,
  uploadedImage,
  canvasRef,
  hazardsdetected,
}) {
  useEffect(() => {
    const dialogs = document.getElementById("AIImagePreview");
    if (dialogs) {
      Array.from(dialogs).forEach((dialogDOM) => {
        const dialog = new Dialog(dialogDOM);
        dialog.init();
      });
    }
  }, []);

  const drawBoundingBoxes = (data, image) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(image, 0, 0, canvas.width, canvas.height);
    const detectionColors = ["#DC2D37"];
    let colorIndex = 0;
    Object.entries(data).forEach(([key, value]) => {
      if (value.boundingBox && value.boundingBox.length > 0) {
        const box = value.boundingBox[0];
        if (
          typeof box.left !== "undefined" &&
          typeof box.top !== "undefined" &&
          typeof box.width !== "undefined" &&
          typeof box.height !== "undefined"
        ) {
          const boxColor = detectionColors[colorIndex % detectionColors.length];
          context.beginPath();
          context.rect(
            box.left * canvas.width,
            box.top * canvas.height,
            box.width * canvas.width,
            box.height * canvas.height
          );
          context.lineWidth = 3;
          context.strokeStyle = boxColor;
          context.stroke();
          const fontSize =
            Math.min(box.width * canvas.width, box.height * canvas.height) *
            0.1;
          context.font = `bold ${fontSize}px Arial`;
          const textWidth = context.measureText(key).width;
          const textHeight = fontSize + 4;
          context.fillStyle = boxColor;
          context.fillRect(
            box.left * canvas.width - 1,
            box.top * canvas.height - textHeight,
            textWidth + 10,
            textHeight
          );
          context.fillStyle = "#FFF";
          const textX = box.left * canvas.width + 5;
          const textY = box.top * canvas.height - 5;
          context.fillText(key, textX, textY);
          colorIndex++;
        }
      }
    });
  };

  useEffect(() => {
    if (hazardsdetected && uploadedImage) {
      const image = new Image();
      image.src = uploadedImage;
      image.onload = () => drawBoundingBoxes(hazardsdetected, image);
    }
  }, [hazardsdetected, uploadedImage]);

  const closeHandler = () => {
    setIsDialogOpen(false);
  };
  return (
    <div
      id="AIImagePreview"
      className="dialog show fullscreen"
      data-trigger="#open-modalList"
      data-type="fullscreen"
    >
      <div className="content">
        <div className="top">
          <div className="right">
            <button className="btn-icon ">
              <i className="icon icon-cross" onClick={closeHandler}></i>
            </button>
          </div>
        </div>
        <div className="body" style={{ marginTop: "1px" }}>
          <div className="row">
            <canvas
              ref={canvasRef}
              style={{
                width: "100%",
                height: "100%",
              }}
            ></canvas>
          </div>
        </div>
      </div>
    </div>
  );
}
