import React, { useEffect } from "react";
import { ColorScale, HorizontalBarChart } from "@eds/vanilla";
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";

export default function SiteWalkGraph({ site_walk_region, regionColumnName }) {
  useEffect(() => {
    const data = {
      common: site_walk_region?.map((obj) => obj?.location),
      series: [
        {
          name: `Count by ${regionColumnName}`,
          values: site_walk_region?.map((obj) => obj?.Count),
        },
      ],
    };
    const chartHeight = data?.common?.length * 40;
    const mycolorScale = new ColorScale({ reverse: true });
    const chart = new HorizontalBarChart({
      element: document.getElementById(
        "totalSiteWalkCompletionCountByLocation"
      ),
      data: data,
      x: { unit: "Count" },
      height: chartHeight,
      colorScale: mycolorScale,
      legend: { isHidden: true },
    });
    mycolorScale.init();
    chart.init();
    return () => {
      chart.destroy();
      let element = document.getElementById(
        "totalSiteWalkCompletionCountByLocation"
      );
      while (element?.firstChild) {
        element.removeChild(element.firstChild);
      }
    };
  }, [site_walk_region, regionColumnName]);

  return (
    <>
      <CustomGraphActions
        nodeId={"totalSiteWalkCompletionCountByLocation"}
        fileName={"Total_SiteWalk_Completion_CountByLocation"}
        data={site_walk_region}
      />
      <div id="totalSiteWalkCompletionCountByLocation"></div>
    </>
  );
}
